import React, {useEffect, useState} from 'react';
import image from '../../../assets/brama.png';
import expandIcon from '../../../assets/icons/expand.png';
import {useDispatch, useSelector} from "react-redux";
import {creatorActions} from "../../../store/slice/gateCreatorSlice";
import {useMutation, useQuery} from "react-query";
import {authorizedArrayPatchWithId, authorizedGet, authorizedPutWithId} from "../CreatorFunctions";
import ExtraAccessoryItem from "./ExtraAccessoryItem";
import {Loader} from "@mantine/core";

const ExtraAccessories = () => {
    const dispatch = useDispatch();
    const creatorItem = useSelector(state => state.gateCreator)
    const [showVisualization, setShowVisualization] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [chosenAccessories, setChosenAccessories] = useState(null)
    const [description, setDescription] = useState(false)
    const chosenHeight = useSelector(state => state.gateCreator.height);
    const {data: accessoriesCategories} = useQuery("accessoriesCategories", () => authorizedGet(`/api/accessory_categories${creatorItem?.gateType?.id ? `?gateType=${creatorItem.gateType.id}` : ""}${creatorItem?.drive?.id ? `&drive=${creatorItem?.drive?.driveGroup?.id}` : ""}`),
        {
            placeholderData: [],
            keepPreviousData: true,
            retry: false,
        })

    const [clickedItem, setClickedItem] = useState(null);
    const updateGateMutation = useMutation(authorizedPutWithId, {
        onSuccess: (d) => {
            dispatch(creatorActions.updateCreator(d))
        }
    })

    const addGateAccessoriesToCartMutation = useMutation(authorizedArrayPatchWithId, {
        retry: 2,
        onSuccess: (s) => {
            const lastItem = s.find(s => !s?.ktmCode?.includes('?'))

            if (lastItem?.id) {
                dispatch(creatorActions.updateItemDetailExtraAccessories(lastItem.gateAccessories))
                dispatch(creatorActions.updateCreator(lastItem))
            }
        }
    })
    const [noAccItems, setNoAccItems] = useState([])

    useEffect(() => {
        const noAccItemsTemp = accessoriesCategories?.filter(aCat => aCat.gateAccessories.filter(acc => creatorItem.width.value >= acc.minWidth && creatorItem.width.value <= acc.maxWidth).length > 0).sort((a, b) => a.name - b.name).map(accesoryType => {
            return {
                type: accesoryType,
                data: accesoryType.gateAccessories.filter(e => e.name.toLocaleLowerCase() === 'brak')[0]
            }
        })

        if (noAccItemsTemp.length > 0) {
            setNoAccItems(noAccItemsTemp)
        }
    }, [accessoriesCategories])
    useEffect(() => {
        if (noAccItems && !creatorItem.gateAccessories.length) {
            addGateAccessoriesToCartMutation.mutate({
                url: `/api/gates/${creatorItem.id}/add_gate_accessory`,
                items: noAccItems
            })
        }
    }, [noAccItems]);

    return (
        <>
            <div className="search-box">
                <input type="text" placeholder="Wyszukaj akcesoria..." onChange={(e) => setSearchText(e.target.value)}/>
            </div>

            <div className="content-items content-items__medium">
                {showVisualization && <div className="visualization">
                    <div className="visualization-box">
                        <img src={image} alt=""/>
                    </div>
                    <div className="close-btn">
                        <img src="./img/icons/close.png" alt=""/>
                    </div>
                    <div className="visualization-buttons">
                        <div className="visualization-buttons__item"><img src="./img/icons/zoom_in.png" alt=""/>
                        </div>
                        <div className="visualization-buttons__item"><img src="./img/icons/zoom_out.png"
                                                                          alt=""/>
                        </div>
                        <div className="visualization-buttons__item"><img src="./img/icons/3d_rotation.png"
                                                                          alt=""/>
                        </div>
                        <div className="visualization-buttons__item"><img src="./img/icons/image.png" alt=""/>
                        </div>
                        <div className="visualization-buttons__item"><img src="./img/icons/fullscreen.png"
                                                                          alt=""/>
                        </div>
                    </div>
                </div>}
                {!addGateAccessoriesToCartMutation.isLoading && searchText.trim().length > 0 && <>
                    <div data-tab="1" className="content-accordion-expand show">
                        {accessoriesCategories?.map(e => e.gateAccessories.filter(item => item.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())).filter(acc => creatorItem.width.value >= acc.minWidth && creatorItem.width.value <= acc.maxWidth).filter(a => !a.isHidden).map((accessory, index) => {

                            return <ExtraAccessoryItem
                                noAccItems={noAccItems}
                                key={accessory.id}
                                accessory={accessory}
                                chosenAccessories={chosenAccessories}
                                creatorItem={creatorItem}
                                setChosenAccessories={setChosenAccessories}
                            />
                        }))}
                    </div>
                </>}
                {addGateAccessoriesToCartMutation.isLoading &&
                    <div style={{padding: 10, paddingBottom: 0}}><Loader/></div>}
                {!addGateAccessoriesToCartMutation.isLoading && searchText.trim().length === 0 &&
                    <div className="items-container show">
                        {accessoriesCategories?.filter(aCat => aCat.gateAccessories.filter(acc => creatorItem.width.value >= acc.minWidth && creatorItem.width.value <= acc.maxWidth).length > 0).sort((a, b) => a.name - b.name).map(accessoryType => {

                            return <div
                                key={accessoryType.id}
                                className="accordion-container">
                                <div className="content-accordion__item">
                                    <p className="content-accordion__title">{accessoryType.name}</p>
                                    <div className="accordion-icons flex">
                                        <img
                                            onClick={(e) => {
                                                setClickedItem(clickedItem === accessoryType.id ? null : accessoryType.id)
                                            }}
                                            data-target="1"
                                            className={`accordion-icons__expand ${clickedItem === accessoryType.id ? "show" : ""}`}
                                            src={expandIcon}
                                            alt="accordion icon"/>
                                    </div>
                                </div>
                                {clickedItem === accessoryType.id &&
                                    <div data-tab="1" className="content-accordion-expand show">
                                        {accessoryType?.gateAccessories.sort((a, b) => a.name - b.name).filter(acc => creatorItem.width.value >= acc.minWidth && creatorItem.width.value <= acc.maxWidth).filter(acc => {
                                            return acc.panelPosition.length ? acc.panelPosition.every(e => e.name <= chosenHeight.panelsAmount) : acc
                                        }).map((accessory, index) => {
                                            return <ExtraAccessoryItem
                                                key={accessory.id}
                                                noAccItems={noAccItems}
                                                accessory={accessory}
                                                accessoryType={accessoryType}
                                                chosenAccessories={chosenAccessories}
                                                creatorItem={creatorItem}
                                                setChosenAccessories={setChosenAccessories}
                                            />
                                        })}
                                    </div>}
                            </div>
                        })}
                    </div>}
            </div>
        </>
    );
};

export default ExtraAccessories;
