import React from "react";
import {motion} from "framer-motion";
import {useMutation, useQueryClient} from "react-query";
import {authorizedDeleteWithId, authorizedPatchWithId} from "../layouts/Creator/CreatorFunctions";
import {creatorActions} from "../store/slice/gateCreatorSlice";
import {useDispatch} from "react-redux";
import {visualizationActions} from "../store/slice/visualizationSlice";

const SummaryBoxExtraAccessories = ({accessory, creatorItem}) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const gateAccessoires = queryClient.getQueryData('accessoriesCategories')

    const addGateAccessoriesToCartMutation = useMutation(authorizedPatchWithId, {
        onSuccess: (s) => {
            dispatch(creatorActions.updateItemDetailExtraAccessories(s.gateAccessories))
            dispatch(creatorActions.updateCreator(s))
        }
    })
    return (
        <>
            <motion.div
                initial={{opacity: 0, y: 5}}
                animate={{opacity: 1, y: 0}}
                transition={{
                    default: {
                        duration: 0.1,
                    },
                }}
                key={accessory.id}
                className="summary-box__item"
            >
                <div className="summary-box__accessories">
                    <p>{accessory?.gateAccessory?.category?.name}</p>
                </div>
                {accessory?.position ? (
                    <div className="extra-accessory">
                        <div className="extra-accessory__item">
                            <strong>Wyrównanie:</strong>
                            <p>
                                {(accessory.alignment === 1 && <span>do prawej</span>) ||
                                    (accessory.alignment === 2 && <span>do lewej</span>) ||
                                    (accessory.alignment === 3 && <span>do środka</span>) ||
                                    (accessory.alignment === 4 && <span>do góry</span>)}
                            </p>
                        </div>
                        <div className="extra-accessory__item">
                            <strong>Pozycja:</strong>
                            <p>{accessory.position}</p>
                        </div>
                    </div>
                ) : null}

                <div
                    className="summary-box__accessories"
                    style={{justifyContent: "flex-end"}}
                >
                    {accessory?.gateAccessory?.name.toLocaleLowerCase() !== 'brak' && <button
                        onClick={() => {
                            const chosenGateAccessoryCat = gateAccessoires.filter(e => e.id === accessory.gateAccessory.category.id)
                            const noAccItem = chosenGateAccessoryCat[0]?.gateAccessories?.filter(e => e.name.toLocaleLowerCase() === 'brak')
                            if (accessory?.gateAccessory?.render?.contentUrl) {
                                dispatch(
                                    visualizationActions.deleteImgVisualization(
                                        accessory.gateAccessory.id
                                    )
                                );
                            }
                            if (noAccItem) {
                                addGateAccessoriesToCartMutation.mutate({
                                    url: `/api/gates/${creatorItem.id}/add_gate_accessory`,
                                    data: {
                                        gate_accessory: noAccItem[0]?.id,
                                        quantity: 1
                                    }
                                })
                            }
                        }}
                    >
                        &#10006;
                    </button>}
                    <div className="summary-box__accessories">
                        <p>{accessory?.gateAccessory?.name}</p>
                    </div>
                </div>
            </motion.div>
        </>
    );
};

export default SummaryBoxExtraAccessories;
