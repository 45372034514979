import React, {useState} from 'react';

import expandIcon from '../../../assets/icons/expand.png';
import eyeIcon from '../../../assets/icons/eye.png';
import {motion} from "framer-motion";
import {useDispatch, useSelector} from "react-redux";
import {creatorActions} from "../../../store/slice/gateCreatorSlice";
import {useQuery} from "react-query";
import {authorizedDeleteWithId, authorizedGet, authorizedGetById} from "../CreatorFunctions";
import {uiActions} from "../../../store/slice/uiSlice";
import {useLocation} from "react-router-dom";


const Accessories = () => {
    const dispatch = useDispatch();
    const creatorItem = useSelector(state => state.gateCreator);
    const [showVisualization, setShowVisualization] = useState(false);
    const [clickedItem, setClickedItem] = useState(1);
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [searchText, setSearchText] = useState("");
    const location  = useLocation()
    const isMiniCreator = location.pathname.startsWith('/mini-creator');
    const {data: clientCart} = useQuery(["cart", creatorItem], () => authorizedGet(`/api/${creatorItem.isOffer ? "offer_carts" : "carts"}`), {
        placeholderData: [],
        enabled: !isMiniCreator,
    })
    const {data: accessoriesCategories} = useQuery("accessoriesCategories", () => authorizedGet("/api/accessory_categories"))


    const accessoriesFiltered = accessoriesCategories?.map(e => e.accessories.filter(item => item.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())).map((accessory, index) => {
        return accessory
    }));
    const filteredAccessories = accessoriesFiltered?.filter(e => e.length !== 0)
    return (
        <>
            <div className="search-box">
                <input type="text" placeholder="Wyszukaj akcesoria..." onChange={(e) => setSearchText(e.target.value)}/>
            </div>
            {<div className="content-items content-items__medium">
                {filteredAccessories?.length === 0 && <motion.p
                    style={{fontWeight: 500, fontSize: 24}}
                    initial={{opacity: 0, y: 5}}
                    animate={{opacity: 1, y: 0}}
                    transition={{
                        default: {
                            duration: .1
                        }
                    }
                    }
                >Nic nie znalezono...</motion.p>}
                {searchText.trim().length > 0 ? <>
                    <div data-tab="1" className="content-accordion-expand show">
                        {accessoriesCategories?.map(e => e.accessories.filter(item => item.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())).map((accessory, index) => {
                            return <motion.div
                                initial={{opacity: 0, y: 5}}
                                animate={{opacity: 1, y: 0}}
                                transition={{
                                    default: {
                                        duration: .1
                                    }
                                }
                                }
                                style={{
                                    backgroundImage: ` linear-gradient(black, white),url(${process.env.REACT_APP_API_UPLOADS + accessory?.image?.contentUrl})`,
                                    backgroundSize: "cover",
                                    backgroundBlendMode: "screen",
                                    maxWidth: '250px'
                                }}
                                key={accessory.id}
                                className="content-item content-item3">
                                <a className="content-item__icon" href="#">
                                    <img
                                        onClick={() => {
                                            dispatch(uiActions.chosenAccessory(accessory))
                                            dispatch(creatorActions.showDescription())
                                        }}
                                        src={eyeIcon}
                                        alt="icon"/>
                                </a>
                                <p className="content-item__title">{accessory.name}</p>
                                <button
                                    disabled={disabledBtn}
                                    onClick={() => {
                                        setDisabledBtn(true)
                                        if (creatorItem?.accessories?.some(e => e.id === accessory.id)) {
                                            authorizedDeleteWithId({url: `/api/${creatorItem.isOffer ? "offer_cart_accessories" : "cart_accessories"}`, id: accessory.id})
                                            dispatch(creatorActions.deleteItemAccessories(accessory.id))
                                        } else {
                                            authorizedGetById("/api/accessories", accessory.id).then(r => {

                                                return r
                                            }).then(e => {
                                                dispatch(creatorActions.updateItemDetailAccessories({
                                                    ...e,
                                                    quantity: 1,
                                                    totalPrice: e.price
                                                }))
                                                setDisabledBtn(false)
                                            })
                                        }

                                        setTimeout(() => {
                                            setDisabledBtn(false)
                                        }, 1000)
                                    }
                                    }
                                    className={`content-item__link ${creatorItem.accessories?.some(e => e?.id === accessory.id) ? "chosen" : null}`}/>
                            </motion.div>
                        }))}
                    </div>
                </> : null}
                {searchText.trim().length === 0 && <div className="items-container show">
                    {accessoriesCategories?.filter(aCat => aCat.accessories.length > 0).sort((a, b) => a.name - b.name).map(accessoryType => {
                        return <div
                            key={accessoryType.id}
                            className="accordion-container">
                            <div className="content-accordion__item">
                                <p className="content-accordion__title">{accessoryType.name}</p>
                                <div className="accordion-icons flex">
                                    <img
                                        onClick={(e) => {
                                            setClickedItem(clickedItem === accessoryType.id ? null : accessoryType.id)
                                        }}
                                        data-target="1"
                                        className={`accordion-icons__expand ${clickedItem === accessoryType.id ? "show" : ""}`}
                                        src={expandIcon}
                                        alt="accordion icon"/>
                                </div>
                            </div>
                            {clickedItem === accessoryType.id &&
                                <div data-tab="1" className="content-accordion-expand show">
                                    {accessoryType.accessories.length === 0 &&
                                        <p>Nie znaleziono akcesoriów pasujących do danej bramy...</p>}
                                    {accessoryType.accessories.length > 0 && accessoryType.accessories.map((accessory, index) => {
                                        return <motion.div
                                            initial={{opacity: 0, y: 5}}
                                            animate={{opacity: 1, y: 0}}
                                            transition={{
                                                default: {
                                                    duration: .1
                                                }
                                            }
                                            }
                                            style={{
                                                backgroundImage: ` linear-gradient(black, white),url(${process.env.REACT_APP_API_UPLOADS + accessory?.image?.contentUrl})`,
                                                backgroundSize: "cover",
                                                backgroundBlendMode: "screen",
                                                maxWidth: '250px',
                                                flex: 1
                                            }}
                                            key={accessory.id}
                                            className="content-item content-item3">
                                            <a className="content-item__icon" href="#">
                                                <img
                                                    onClick={() => {
                                                        dispatch(uiActions.chosenAccessory(accessory))
                                                        dispatch(creatorActions.showDescription())
                                                    }}
                                                    src={eyeIcon}
                                                    alt="icon"/>
                                            </a>
                                            <p className="content-item__title">{accessory.name}</p>
                                            <button
                                                disabled={disabledBtn}
                                                onClick={() => {
                                                    setDisabledBtn(true)
                                                    if (creatorItem?.accessories?.some(e => e.id === accessory.id)) {
                                                        authorizedDeleteWithId({url: `/api/${creatorItem.isOffer ? "offer_cart_accessories" : "cart_accessories"}`, id: accessory.id})
                                                        dispatch(creatorActions.deleteItemAccessories(accessory.id))
                                                    } else {
                                                        authorizedGetById("/api/accessories", accessory.id).then(r => {

                                                            return r
                                                        }).then(e => {
                                                            dispatch(creatorActions.updateItemDetailAccessories({
                                                                ...e,
                                                                quantity: 1,
                                                                totalPrice: e.price
                                                            }))
                                                            setDisabledBtn(false)
                                                        })
                                                    }
                                                    setTimeout(() => {
                                                        setDisabledBtn(false)
                                                    }, 1000)
                                                }
                                                }
                                                className={`content-item__link ${creatorItem.accessories?.some(e => e?.id === accessory.id) ? "chosen" : null}`}/>
                                        </motion.div>
                                    })}
                                </div>}
                        </div>
                    })}
                </div>}
            </div>}
        </>);
};

export default Accessories;
