import React, {useEffect} from 'react';
import offerImage from '../../assets/nowa-oferta.png';
import offerHistoryImage from '../../assets/historia-ofert.png';
import offerBasketImage from '../../assets/koszyk-ofert.png';
import newClientImage from '../../assets/nowy-klient.png';
import clientListImage from '../../assets/lista-klientow.png';
import orderImage from '../../assets/nowe-zam.png';
import orderHistoryImage from '../../assets/historia-zam.png';
import orderBasketImage from '../../assets/koszyk-zam.png';
import addIcon from '../../assets/icons/add.png';
import timeIcon from '../../assets/icons/time.png';
import shopIcon from '../../assets/icons/shop.png';
import listIcon from '../../assets/icons/list.png';
import {Link} from "react-router-dom";
import {motion} from "framer-motion";
import {cartActions} from "../../store/slice/cartSlice";
import {useDispatch, useSelector} from "react-redux";
import {creatorActions} from "../../store/slice/gateCreatorSlice";
import {summaryBoxActions} from "../../store/slice/summarySlice";
import {useQuery, useQueryClient} from "react-query";
import {authorizedDeleteWithId, authorizedGet, authorizedGetById} from "../Creator/CreatorFunctions";
import {visualizationActions} from "../../store/slice/visualizationSlice";
import {uiActions} from "../../store/slice/uiSlice";


const WelcomePage = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const creatorItem = useSelector(state => state.gateCreator);
    const updateCreator = (data) => {
        dispatch(creatorActions.updateCreator(data.creator))
        dispatch(cartActions.isOfferToggle(data.cart))
    }

    useEffect(() => {
        if (creatorItem?.accessories.length > 0) {
            creatorItem?.accessories?.forEach(e => {
                authorizedDeleteWithId({
                    url: "/api/cart_accessories",
                    id: e.id
                })
            })
        }
        dispatch(creatorActions.resetCreator())
        dispatch(summaryBoxActions.updateNumberOfSteps(4))
        dispatch(visualizationActions.resetVisualization())
        dispatch(visualizationActions.resetBackgroundToVisualization())
        dispatch(uiActions.editModeOff(null))
    }, [])

    const {data: client} = useQuery(["client", user], () => authorizedGetById("/api/clients", user.userId))
    const {data: clientCart} = useQuery("cart", () => authorizedGet("/api/carts"))
    const {data: clientOfferCart} = useQuery("offerCart", () => authorizedGet("/api/offer_carts"))

    return (
        <>
            <motion.h2
                initial={{opacity: 0, y: 5}}
                animate={{opacity: 1, y: 0}}
                transition={{
                    default: {
                        duration: .1
                    }
                }
                }
                className="dashboard-title">Witaj {user.userName || ""}</motion.h2>
            <div className="dashboard-container-main">
                <motion.div
                    initial={{opacity: 0, y: 5}}
                    animate={{opacity: 1, y: 0}}
                    transition={{
                        default: {
                            duration: .1
                        }
                    }
                    }
                    className="dashboard-container-main__item">
                    <p>Oferty</p>
                    <div className="dashboard-item dashboard-item__big">
                        <Link
                            onClick={() => updateCreator({
                                creator: {
                                    isOffer: true,
                                    isOrder: false
                                },
                                cart: true
                            })}
                            to="/creator/step/0">
                            <img src={offerImage} alt="background image"/>
                            <p>Nowa oferta</p>
                            <img src={addIcon} alt="background image"
                                 className="dashboard-item__icon"/>
                        </Link>

                    </div>
                    <div className="small-item__container">
                        <div className="dashboard-item dashboard-item__small">
                            <Link to="/history/offers">
                                <img src={offerHistoryImage} alt="background image"/>
                                <p>Historia ofert</p>
                                <img src={timeIcon} alt="background image"
                                     className="dashboard-item__icon"/>
                            </Link>
                        </div>
                        <div className="dashboard-item dashboard-item__small">
                            <Link
                                onClick={() => dispatch(cartActions.isOfferToggle(true))}
                                to="/cart/offer_carts">
                                <img src={offerBasketImage} alt="background image"/>
                                <p>Koszyk ofert</p>
                                <img src={shopIcon} alt="background image"
                                     className="dashboard-item__icon"/>
                            </Link>
                        </div>
                    </div>
                </motion.div>
                <motion.div
                    initial={{opacity: 0, y: 5}}
                    animate={{opacity: 1, y: 0}}
                    transition={{
                        delay: .1,
                        default: {
                            duration: .1
                        }
                    }
                    }
                    className="dashboard-container-main__item">
                    <p>Zamówienia</p>
                    <div className="dashboard-item dashboard-item__big">
                        <Link
                            onClick={() => updateCreator({
                                creator: {
                                    isOffer: false,
                                    isOrder: true
                                },
                                cart: false
                            })}
                            to={`/creator/step/0`}>
                            <img src={orderImage} alt="background image"/>
                            <p>Nowe zamówienie</p>
                            <img src={addIcon} alt="background image"
                                 className="dashboard-item__icon"/>
                        </Link>

                    </div>
                    <div className="small-item__container">
                        <div className="dashboard-item dashboard-item__small">
                            <Link
                                to="/history/orders">
                                <img src={orderHistoryImage} alt="background image"/>
                                <p>Historia zamówień</p>
                                <img src={timeIcon} alt="background image"
                                     className="dashboard-item__icon"/>
                            </Link>

                        </div>
                        <div className="dashboard-item dashboard-item__small">
                            <Link
                                onClick={() => dispatch(cartActions.isOfferToggle(false))}
                                to="/cart/carts">
                                <img src={orderBasketImage} alt="background image"/>
                                <p>Koszyk zamówień</p>
                                <img src={shopIcon} alt="background image"
                                     className="dashboard-item__icon"/>
                            </Link>
                        </div>
                    </div>
                </motion.div>
                {user.isDistributor && <motion.div
                    initial={{opacity: 0, y: 5}}
                    animate={{opacity: 1, y: 0}}
                    transition={{
                        delay: .2,
                        default: {
                            duration: .1
                        }
                    }
                    }
                    className="dashboard-container-main__item">
                    <p>Klienci</p>
                    <div className="dashboard-item dashboard-item__big">
                        <Link to="/client/new">
                            <img src={newClientImage} alt="background image"/>
                            <p>Nowy klient</p>
                            <img src={addIcon} alt="background image"
                                 className="dashboard-item__icon"/>
                        </Link>

                    </div>
                    <div className="small-item__container">
                        <div className="dashboard-item dashboard-item__small">
                            <Link to="/clients">
                                <img src={clientListImage} alt="background image"/>
                                <p>Lista klientów</p>
                                <img src={listIcon} alt="background image"
                                     className="dashboard-item__icon"/>
                            </Link>
                        </div>
                        <div className="dashboard-item dashboard-item__small">
                            <Link to="/orders_clients">
                                <img src={clientListImage} alt="background image"/>
                                <p>Zamówienia klientów</p>
                                <img src={listIcon} alt="background image"
                                     className="dashboard-item__icon"/>
                            </Link>
                        </div>
                    </div>
                </motion.div>}
            </div>
        </>
    );
};

export default WelcomePage;
