import React, {useEffect, useState} from 'react';
import './LoginForm.scss';
import enterIcon from '../../assets/icons/exit.png';
import eyeIcon from "../../assets/icons/eye.png";
import {Link, useHistory} from 'react-router-dom';
import useInput from "../../hooks/useInput";
import {useDispatch, useSelector} from "react-redux";
import {authActions} from "../../store/slice/authSlice";
import jwt from 'jwt-decode';
import {userActions} from "../../store/slice/userSlice";
import {useQueryClient} from "react-query";
import {Loader} from '@mantine/core';

const errorStyle = {
    color: "red",
    fontSize: 12
}
const LoginForm = ({isLoggedInCheck}) => {
    const queryClient = useQueryClient();
    const content = queryClient.getQueryData("contents")
    const [loading, setLoading] = useState(false)

    const history = useHistory();
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const {
        value: enteredEmail,
        isTouched: enteredEmailIsTouched,
        hasError: enteredEmailHasError,
        enteredValueChangeHandler: enteredEmailChangeHandler,
        inputBlurHandler: emailBlurHandler,
        reset: resetEnteredEmail
    } = useInput(enteredValue => enteredValue?.includes("@"));

    const {
        value: enteredPassword,
        isTouched: enteredPasswordIsTouched,
        hasError: enteredPasswordHasError,
        enteredValueChangeHandler: enteredPasswordChangeHandler,
        inputBlurHandler: PasswordBlurHandler,
        reset: resetEnteredPassword
    } = useInput(enteredValue => enteredValue?.trim().length > 4);

    const [error, setError] = useState({
        isError: false,
        errorMessage: ""
    })
    const isFormValid = (!enteredEmailHasError && enteredEmailIsTouched) && (!enteredPasswordHasError && enteredPasswordIsTouched)

    const submitHandler = (e) => {
        e.preventDefault();
        setLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
            method: "POST",
            body: JSON.stringify({
                email: enteredEmail,
                password: enteredPassword,
            }),
            headers: {
                "Content-Type": "application/json"
            }
        }).then(response => {
            if (response.ok) {
                return response.json()
            } else {
                return response.json().then(err => {
                    setLoading(false)
                    setError({isError: true, errorMessage: "Niepoprawne dane logowania."})
                })
            }
        }).then(data => {
            const user = jwt(data.token)
            if(user?.isActive && (user.roles.includes('ROLE_CLIENT') || user.roles.includes('ROLE_DISTRIBUTOR'))) {
                dispatch(userActions.updateUser(user))
                dispatch(authActions.logIn(data))
                resetEnteredEmail()
                resetEnteredPassword()
                setLoading(false)
                history.push("/")
            } else {
                setLoading(false)
                setError({isError: true, errorMessage:user?.isActive ? 'Użytkownik o podanym emailu nie istnieje!': "Konto zostało dezaktywowane!"})
            }
        })
    }

    return (
        <section className="section-login-form">
            <form onSubmit={submitHandler} className="form">
                <h2>{content?.find(e =>e.slug === "LoginTitle")?.content}</h2>
                <div className="form--inp">
                    <label htmlFor="email">E-mail</label>
                    <input value={enteredEmail}
                           onChange={enteredEmailChangeHandler}
                           onBlur={emailBlurHandler}
                           type="email"
                           id="email"
                           placeholder="Wpisz e-mail"
                           required style={enteredEmailHasError ? {borderColor: "red"} : null}/>
                    {enteredEmailHasError && <span style={errorStyle}>Wprowadzony adres email jest niepoprawny</span>}
                </div>

                <div className="form--inp">
                    <label htmlFor="password">Hasło</label>
                    <input onChange={enteredPasswordChangeHandler}
                           onBlur={PasswordBlurHandler}
                           value={enteredPassword}
                           type={showPassword ? "text" : "password"}
                           id="password"
                           placeholder="Wpisz hasło"
                           required
                           style={enteredPasswordHasError ? {borderColor: "red"} : null}>
                    </input>
                    <img
                        onClick={() => setShowPassword(!showPassword)}
                        className="custom-icons"
                        src={eyeIcon} alt="login icon"
                    />
                    {enteredPasswordHasError && <span style={errorStyle}>Wprowadzone hasło jest za krótkie</span>}
                </div>
                {error.isError &&
                <p style={{color: "red", fontSize: 14, marginBottom: 15, fontWeight: 600}}>{error.errorMessage}</p>}
                <button
                    type={"submit"}
                    disabled={!isFormValid}
                    className="btn btn-primary">
                    {!loading && <><span>Zaloguj się</span>
                    <img className="custom-icons" src={enterIcon} alt="login icon"/></>}
                    {loading && <Loader color={"white"} size={20}/>}
                </button>
                <div className="form__underline"></div>

                <p className="mb-0 body-m text-center"><Link to="/login/password-forgot">Nie pamiętam
                    hasła</Link> lub <Link to="/register">nie masz jeszcze konta</Link>?</p>
            </form>
        </section>
    );
};

export default LoginForm;
