import {createSlice} from "@reduxjs/toolkit";


const contentSlice = createSlice({
    name: "content",
    initialState: {},
    reducer: {
        updateContent(state, action) {
            return state = action.payload
        }
    }
})

export const contentSliceActions = contentSlice.actions;

export default contentSlice.reducer;