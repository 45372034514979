import React, {useEffect, useState} from 'react';
import expandIcon from "../../../assets/icons/expand.png";
import {motion} from "framer-motion";
import {useHistory, useParams} from "react-router-dom";
import {creatorActions} from "../../../store/slice/gateCreatorSlice";
import {useDispatch, useSelector} from "react-redux";
import {useMutation, useQuery} from "react-query";
import {
    authorizedGetById,
    authorizedPatchWithId,
    authorizedPost,
    authorizedPutWithId
} from "../../Creator/CreatorFunctions";
import {Checkbox, Select} from "@mantine/core";

const CartPreview = ({
                         receiverFromSelect,
                         updateCartPrice,
                         setPriceData,
                         setChosenAcc,
                         chosenAcc,
                         setChosenItems,
                         chosenItems,
                         setInternalNumber,
                         setReceiver,
                         receiver,
                         setReceiverFromSelect,
                         setValidUntil,
                         setOrderNotes,
                         cart,
                         clickedItem,
                         setClickedItem
                     }) => {
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const accessToken = localStorage.getItem("token");
    const params = useParams();
    const isOrder = params?.type === 'carts';
    const creatorItem = useSelector(state => state.gateCreator);
    const [isOneTimeOffer, setIsOneTimeOffer] = useState(false)
    const history = useHistory();
    const addGateAccessoriesToCartMutation = useMutation(authorizedPatchWithId, {
        onSuccess: (s) => {
            dispatch(creatorActions.updateItemDetailExtraAccessories(s.gateAccessories))
        }
    });

    const {data: client, isLoading} = useQuery(["client", user], () => authorizedGetById("/api/clients", user.userId), {
        keepPreviousData: true
    })
    const [options, setOptions] = useState([])
    const [companyOptions, setCompanyOptions] = useState([])
    useEffect(() => {
        if (client?.clients) {
            setOptions(client?.clients.map(c => ({value: JSON.stringify(c), label: c.name + " " + c.surname})))
            setCompanyOptions(client?.clients.map(c => ({
                value: JSON.stringify(c),
                label: c.company ? c.company : "Brak podanej firmy..."
            })))
        }
    }, [client]);


    useEffect(() => {
        setChosenItems([])
        setChosenAcc([])
    }, []);

    useEffect(() => {
        if (!!chosenItems.length || !!chosenAcc?.length) {
            updateCartPrice.mutate({
                url: `/api/${params.type}/price_from_cart`,
                data: {
                    items: chosenItems.map(e => e.id),
                    accessories: chosenAcc.map(e => e.id)
                }
            }, {
                onSuccess: d => {
                    setPriceData(prev => {
                        return (
                            {
                                ...prev,
                                priceData: d
                            }
                        )
                    })
                }
            })

            if (!chosenItems.length && !chosenAcc.length) {
                setPriceData(prev => {
                    return (
                        {
                            ...prev,
                            priceData: {
                                finalPrice: 0,
                                netPrice: 0,
                                regularNetPrice: 0,
                                regularPrice: 0
                            }
                        }
                    )
                })
            }
        }
    }, [chosenItems, chosenAcc]);

    return (
        <motion.section
            initial={{opacity: 0, y: 5}}
            animate={{opacity: 1, y: 0}}
            transition={{
                delay: .2,
                default: {
                    duration: .1
                }
            }
            }
            className="order-view">
            <div className="search-box show">
                <div className="search-box-title-container">
                    <h3>Dane do {isOrder ? 'zamówienia' : 'oferty'}</h3>
                    {user?.isDistributor && params.type === "offer_carts" && <div className="search-box-checkbox">
                        <Checkbox size={'sm'} onChange={() => setIsOneTimeOffer(prev => !prev)}/>
                        <p>Jednorazowa oferta</p>
                    </div>}
                </div>

                <div className="search-box-inputs">
                    <div>
                        <div>
                            <label htmlFor="">Numer wewnętrzny {isOrder ? 'zamówienia' : 'oferty'}</label>
                            <input
                                onChange={e => {
                                    setInternalNumber(e.target.value)
                                }}
                                className="input-long input"
                                type="text"
                                placeholder={`Wpisz numer wewnętrzny ${isOrder ? 'zamówienia' : 'oferty'}...`}/>
                        </div>
                        {!isOrder && <div>
                            <label htmlFor="">Imię i nazwisko klienta</label>
                            {!isOneTimeOffer && user.isDistributor ? <Select
                                data={options}
                                value={receiverFromSelect}
                                className="input-long input"
                                placeholder="Wprowadź klienta...."
                                nothingFound="Brak klientów"
                                searchable={true}
                                onChange={v => {
                                    setReceiverFromSelect(v)
                                }}
                                filter={(value, item) =>
                                    item?.value?.toLowerCase()?.includes(value.toLowerCase().trim())
                                }
                            /> : <input
                                value={receiver.receiverName ? receiver.receiverName : null}
                                onChange={e => setReceiver(prev => ({...prev, receiverName: e.target.value}))}
                                className="input-long"
                                type="text" placeholder="Wprowadź imię i nazwisko klienta...."
                            />}
                        </div>}
                    </div>
                </div>
                {!isOrder && <>
                    <div className="search-box-inputs" style={{marginTop: 10}}>
                        <div>
                            <div>
                                <label htmlFor="">Firma klienta</label>
                                {!isOneTimeOffer && user.isDistributor ? <Select
                                    data={companyOptions}
                                    className="input-long input"
                                    value={receiverFromSelect}
                                    placeholder="Wprowadź firmę klienta...."
                                    nothingFound="Brak klientów"
                                    searchable={true}
                                    onChange={v => {
                                        setReceiverFromSelect(v)
                                    }}
                                    filter={(value, item) =>
                                        item?.value?.toLowerCase()?.includes(value.toLowerCase().trim())
                                    }
                                /> : <input
                                    value={receiver.receiverCompany ? receiver.receiverCompany : null}
                                    onChange={e => setReceiver(prev => ({...prev, receiverCompany: e.target.value}))}
                                    className="input-long"
                                    type="text" placeholder="Wprowadź firmę klienta...."
                                />}
                            </div>
                            <div>
                                <label htmlFor="">Adres klienta</label>
                                <input
                                    value={receiver.receiverAddress ? receiver.receiverAddress : null}
                                    onChange={e => setReceiver(prev => ({...prev, receiverAddress: e.target.value}))}
                                    className="input-long"
                                    type="text" placeholder="Adres miejscowość...."
                                />
                            </div>
                        </div>
                    </div>
                    <div className="search-box-inputs" style={{marginTop: 10}}>
                        <div>
                            <div>
                                <label htmlFor="">Miejscowość klienta</label>
                                <input
                                    value={receiver.receiverCity ? receiver.receiverCity : null}
                                    onChange={e => setReceiver(prev => ({...prev, receiverCity: e.target.value}))}
                                    className="input-long"
                                    type="text" placeholder="Miejscowość klienta...."
                                />
                            </div>
                            <div>
                                <label htmlFor="">Województwo klienta</label>
                                <input
                                    value={receiver.receiverState ? receiver.receiverState : null}
                                    onChange={e => setReceiver(prev => ({...prev, receiverState: e.target.value}))}
                                    className="input-long"
                                    type="text" placeholder="Województwo klienta...."
                                />
                            </div>
                        </div>
                    </div>
                    <div className="search-box-inputs" style={{marginTop: 10}}>
                        <div>
                            <div>
                                <label htmlFor="">Kod miejscowość klienta</label>
                                <input
                                    value={receiver.receiverZipCode ? receiver.receiverZipCode : null}
                                    onChange={e => setReceiver(prev => ({...prev, receiverZipCode: e.target.value}))}
                                    className="input-long"
                                    type="text" placeholder="Kod miejscowość klienta...."
                                />
                            </div>
                            <div>
                                <label htmlFor="">Państwo klienta</label>
                                <input
                                    value={receiver.receiverCountry ? receiver.receiverCountry : null}
                                    onChange={e => setReceiver(prev => ({...prev, receiverCountry: e.target.value}))}
                                    className="input-long"
                                    type="text" placeholder="Państwo klienta...."
                                />
                            </div>
                        </div>
                    </div>
                    <div className="search-box-inputs" style={{marginTop: 10}}>
                        <div>
                            <div>
                                <label htmlFor="">Telefon klienta</label>
                                <input
                                    value={receiver.receiverPhone ? receiver.receiverPhone : null}
                                    onChange={e => setReceiver(prev => ({...prev, receiverPhone: e.target.value}))}
                                    className="input-long"
                                    type="text" placeholder="Telefon klienta...."
                                />
                            </div>
                            <div>
                                <label htmlFor="">Adres email klienta</label>
                                <input
                                    value={receiver.receiverEmail ? receiver.receiverEmail : null}
                                    onChange={e => setReceiver(prev => ({...prev, receiverEmail: e.target.value}))}
                                    className="input-long"
                                    type="text" placeholder="Adres email klienta...."
                                />
                            </div>
                        </div>
                    </div>
                </>}

            </div>
            <div className="content-items content-items__medium">
                <div className="items-container show">
                    <div className="accordion-container">
                        <div className="content-accordion__item">
                            <h3>Zamówione projekty</h3>
                        </div>
                        <div style={{height: 20, background: 'white'}}/>
                        {cart?.length > 0 && cart.map((item, index) => {
                            if (item?.gate?.id) {
                                return (
                                    <>
                                        <div className="content-accordion__item">
                                            <Checkbox
                                                checked={!!chosenItems.find(e => e.id === item.id)}
                                                onChange={e => {
                                                    const isChosen = chosenItems.find(e => e.id === item.id)
                                                    if (!isChosen) {
                                                        setChosenItems(prev => [...prev, item])
                                                    } else {
                                                        setChosenItems(prevState => prevState.filter(e => e.id !== item.id))
                                                    }
                                                }
                                                }/>
                                            <div>
                                                <div style={{display: 'flex', gap: 10}}>

                                                    <p className="content-accordion__title">{item?.gate?.gateType?.name}</p>
                                                </div>

                                                <div style={{display: 'flex', marginTop: 10, gap: 10}}>
                                                    <p style={{
                                                        textAlign: 'center',
                                                        color: '#1D446F',
                                                        fontWeight: 'bold',
                                                        fontSize: 12
                                                    }}>Cena
                                                        (netto) {client.isDistributor ? 'po rabacie dla dystrybutora' : null}:</p>
                                                    <p style={{textAlign: 'center', fontSize: 12}}>
                                                        {(item?.gate?.gateType?.name.toLowerCase().includes('przemysłowa') && !item?.gate?.leading?.priceType === 3) &&<strong>Cena na zapytanie</strong>}
                                                        {!(item?.gate?.gateType?.name.toLowerCase().includes('przemysłowa') && !item?.gate?.leading?.priceType === 3) && <strong>{(item?.priceData.netPrice / 100).toFixed(2)}  PLN</strong>}
                                                    </p>
                                                </div>
                                            </div>


                                            <div className="content-accordion__button"/>
                                            <div className="accordion-icons flex" style={{alignItems: 'center'}}>
                                                <p>{clickedItem !== item.id ? 'Rozwiń' : 'Zwiń'}</p>
                                                <img data-target="1"
                                                     onClick={(e) => {
                                                         setClickedItem(prev => {
                                                             if (prev === item.id) {
                                                                 return null
                                                             } else {
                                                                 return item.id
                                                             }
                                                         })
                                                     }}
                                                     className={`accordion-icons__expand  ${clickedItem !== item.id ? "" : "show"}`}
                                                     src={expandIcon}
                                                     alt="accordion icon"/>
                                            </div>
                                        </div>
                                        {<motion.div
                                            initial={{opacity: 0, y: 5}}
                                            animate={{opacity: 1, y: 0}}
                                            transition={{
                                                delay: .2,
                                                default: {
                                                    duration: .1
                                                }
                                            }
                                            }
                                            data-tab="1" className="content-accordion-expand">
                                            <div className="basket-container">
                                                <div className="basket-left">
                                                    <div className="basket-box">
                                                        <div className="basket-item">
                                                            <p className="basket-item__left">KTM</p>
                                                            <p className="basket-item__right">{item?.gate?.ktmCode}</p>
                                                        </div>
                                                    </div>
                                                    <div className="basket-box">
                                                        <div className="basket-item">
                                                            <p className="basket-item__left">KTM - opis</p>
                                                            <p className="basket-item__right">{item?.gate?.ktmCodeDescription}</p>
                                                        </div>
                                                    </div>
                                                    <div className="basket-box">
                                                        <div className="basket-item">
                                                            <p className="basket-item__left">Ilość</p>
                                                            <p className="basket-item__right">{item?.quantity}</p>
                                                        </div>
                                                    </div>
                                                    {clickedItem === item.id && <motion.div>
                                                        <div className="basket-box">
                                                            <div className="basket-item">
                                                                <p className="basket-item__left">Typ bramy</p>
                                                                <p className="basket-item__right">{item?.gate?.gateType?.name}</p>
                                                            </div>
                                                        </div>
                                                        <div className="basket-box">
                                                            {item?.gate?.width?.value && <div className="basket-item">
                                                                <p className="basket-item__left">Szerokość</p>
                                                                <p className="basket-item__right">{item?.gate?.width?.value} mm</p>
                                                            </div>}
                                                            {item?.gate?.height?.value && <div className="basket-item">
                                                                <p className="basket-item__left">Wysokość</p>
                                                                <p className="basket-item__right">{item?.gate?.height?.value} mm</p>
                                                            </div>}
                                                            {item?.gate?.depth && <div className="basket-item">
                                                                <p className="basket-item__left">Głębokość</p>
                                                                <p className="basket-item__right">{item?.gate?.depth} mm</p>
                                                            </div>}
                                                            {item?.gate?.rightArea && <div className="basket-item">
                                                                <p className="basket-item__left">Przestrzeń boczna z
                                                                    prawej</p>
                                                                <p className="basket-item__right">{item?.gate?.rightArea} mm</p>
                                                            </div>}
                                                            {item?.gate?.leftArea && <div className="basket-item">
                                                                <p className="basket-item__left">Przestrzeń boczna z
                                                                    lewej</p>
                                                                <p className="basket-item__right">{item?.gate?.leftArea} mm</p>
                                                            </div>}
                                                            {item?.gate?.lintel?.value && <div className="basket-item">
                                                                <p className="basket-item__left">Nadproże</p>
                                                                <p className="basket-item__right">{item?.gate?.lintel?.value} mm</p>
                                                            </div>}
                                                            {item?.gate?.drive?.name && <div className="basket-item">
                                                                <p className="basket-item__left">Napęd</p>
                                                                <p className="basket-item__right">{item?.gate?.drive?.name}</p>
                                                            </div>}
                                                            {item?.gate?.leading?.name && <div className="basket-item">
                                                                <p className="basket-item__left">Prowadzenie</p>
                                                                <p className="basket-item__right">{item?.gate?.leading?.name}</p>
                                                            </div>}
                                                            {item?.gate?.roofAngle && <div className="basket-item">
                                                                <p className="basket-item__left">Kąt nachylenia
                                                                    dachu</p>
                                                                <p className="basket-item__right">{item?.gate?.roofAngle}</p>
                                                            </div>}
                                                            {item?.gate?.componentItems.length > 0 && item.gate.componentItems.map(comp => {
                                                                return (
                                                                    <div className="basket-item">
                                                                        <p className="basket-item__left">{comp?.component?.name}</p>
                                                                        <p className="basket-item__right">{comp?.name}</p>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>

                                                    </motion.div>}

                                                    {clickedItem === item.id && <div className="basket-box">
                                                        <div className="basket-item">
                                                            <p className="basket-item__left">Akcesoria budowy bramy:</p>
                                                        </div>
                                                        {item?.gate?.gateAccessories?.map(i => {
                                                            return (
                                                                <div className="basket-item">
                                                                    <p className="basket-item__left">{i?.gateAccessory?.category?.name}</p>
                                                                    {i.position ? <p className="basket-item__right">
                                                                            {"Pozycja: " + i.position + " , Wyrówananie: "}
                                                                            {i.alignment === 1 &&
                                                                                <span>do prawej</span> || i.alignment === 2 &&
                                                                                <span>do lewej</span> || i.alignment === 3 &&
                                                                                <span>do środka</span> || i.alignment === 4 &&
                                                                                <span>do góry</span>}
                                                                        </p> :
                                                                        <p className="basket-item__right">{i?.gateAccessory?.name} {i?.gateAccessory?.name.toLocaleLowerCase() !== 'brak' ? `(x${i.quantity})` : ''}</p>}
                                                                </div>
                                                            )
                                                        })}
                                                    </div>}
                                                </div>

                                            </div>

                                        </motion.div>}
                                        {index !== cart.length - 1 &&
                                            <div style={{height: 10, background: '#F6F5F5'}}/>}
                                    </>
                                )
                            }
                            if (item?.accessory?.id) {
                                return (
                                    <React.Fragment key={item.id}>
                                        <div className="content-accordion__item">
                                            <Checkbox
                                                checked={!!chosenAcc.find(e => e.id === item.id)}
                                                onChange={e => {
                                                    const isChosen = chosenAcc.find(e => e.id === item.id)
                                                    if (!isChosen) {
                                                        setChosenAcc(prev => [...prev, item])
                                                    } else {
                                                        setChosenAcc(prevState => prevState.filter(e => e.id !== item.id))
                                                    }
                                                }
                                                }/>
                                            <div>
                                                <div style={{display: 'flex', gap: 10}}>
                                                    <p className="content-accordion__title">{item?.accessory?.name}</p>
                                                </div>

                                                <div style={{display: 'flex', marginTop: 10, gap: 10}}>
                                                    <p style={{
                                                        textAlign: 'center',
                                                        color: '#1D446F',
                                                        fontWeight: 'bold',
                                                        fontSize: 12
                                                    }}>Cena (netto):</p>
                                                    <p style={{textAlign: 'center', fontSize: 12}}>
                                                        <strong>{(item?.priceData.netPrice / 100).toFixed(2)} PLN</strong>
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="content-accordion__button"/>

                                            <div className="accordion-icons flex" style={{alignItems: 'center'}}>
                                                <p>{clickedItem === item.id ? 'Rozwiń' : 'Zwiń'}</p>
                                                <img data-target="1"
                                                     src={expandIcon}
                                                     alt="accordion icon"
                                                     onClick={(e) => {
                                                         setClickedItem(prev => {
                                                             if (prev === item.id) {
                                                                 return null
                                                             } else {
                                                                 return item.id
                                                             }
                                                         })
                                                     }}
                                                     className={`accordion-icons__expand  ${clickedItem === item.id ? "" : "show"}`}/>
                                            </div>

                                        </div>
                                        {index !== cart.length - 1 && index !== 0 &&
                                            <div style={{height: 10, background: '#F6F5F5'}}/>}
                                        {clickedItem !== item.id &&
                                            <motion.div
                                                initial={{opacity: 0, y: 5}}
                                                animate={{opacity: 1, y: 0}}
                                                transition={{
                                                    delay: .2,
                                                    default: {
                                                        duration: .1
                                                    }
                                                }
                                                } data-tab="1" className="content-accordion-expand show">
                                                <div className="basket-container">
                                                    <div className="basket-left">
                                                        <div className="basket-box">
                                                            <div className="basket-item">
                                                                <p className="basket-item__left">KTM</p>
                                                                <p className="basket-item__right">{item?.accessory?.ktm}</p>
                                                            </div>
                                                        </div>
                                                        <div className="basket-box">
                                                            <div className="basket-item">
                                                                <p className="basket-item__left">KTM - opis</p>
                                                                <p className="basket-item__right">{item?.accessory?.ktmDescription}</p>
                                                            </div>
                                                        </div>
                                                        <div className="basket-box">
                                                            <div className="basket-item">
                                                                <p className="basket-item__left">Ilość</p>
                                                                <p className="basket-item__right">{item?.quantity}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </motion.div>}
                                    </React.Fragment>
                                )
                            }
                        })}
                    </div>
                </div>
            </div>
            <div className="comment-box show">
                <h3>Uwagi do {isOrder ? 'zamówienia' : 'oferty'}</h3>
                <div className="search-box-inputs">
                    <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                        <div>
                            <textarea
                                className="input-long"
                                placeholder={`Uwagi do ${isOrder ? 'zamówienia' : 'oferty'}`}
                                onChange={e => setOrderNotes(e.target.value)}
                            />
                        </div>
                        {creatorItem.isOffer && <>
                            <h4>Termin ważności</h4>
                            <div>
                                <input
                                    onChange={(e) => {

                                        setValidUntil(e.target.value)
                                    }}
                                    type="date"
                                />
                            </div>
                        </>}
                    </div>
                </div>
            </div>
        </motion.section>
    );
};

export default CartPreview;
