import {createSlice} from "@reduxjs/toolkit";

const uiSlice = createSlice({
        name: "ui",
        initialState: {
            isEdit: false
        },
        reducers: {
            isOfferToggle(state, action) {
                state.isOffer = action.payload
            },
            chosenAccessory(state, action) {
                state.chosenAccessory = action.payload
            },
            editModeOn(state) {
                state.isEdit = true
            },
            editModeOff(state) {
                state.isEdit = false
            }
        }
    }
)

export const uiActions = uiSlice.actions;

export default uiSlice.reducer;