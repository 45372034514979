import React, {useState} from 'react';
import styles from './Header.scss';
import logo from '../../assets/l.png';
import exitIcon from '../../assets/icons/exit.png';
import {Link, NavLink, useHistory} from "react-router-dom";
import {summaryBoxActions} from "../../store/slice/summarySlice";
import {useDispatch} from "react-redux";
import {authActions} from "../../store/slice/authSlice";


const Header = () => {
    const dispatch = useDispatch();
    const [openMenu, setOpenMenu] = useState(false)
    const handleLogout = () => {
        dispatch(authActions.logOut())

        window.location.reload();
    }
    return (
        <header>
            <div className="header-container">
                <NavLink to="/"  onClick={() => dispatch(summaryBoxActions.resetCurrentStep())}>
                    <img className="login-logo" src={logo} alt="logo"/>
                </NavLink>
                <nav>
                    <div id="burger-icon"
                         className={openMenu ? "open" : ""}
                         onClick={() => setOpenMenu(prev => !prev)}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <ul

                        className={`menu ${openMenu ? "open" : ""}`}>
                        <li><NavLink to="/"
                                     onClick={() => dispatch(summaryBoxActions.resetCurrentStep())}>Dashboard</NavLink>
                        </li>
                        <li className="sub-menu">
                            <a href="#">Moje konto</a>
                            <ul className="sub-menu-container">
                                <li><NavLink to="/user/data-edit/profile">Moje dane</NavLink></li>
                                <li><NavLink to="/user/data-edit/email">Mój email</NavLink></li>
                                <li><NavLink to="/user/data-edit/password">Moje hasło</NavLink></li>
                                <li><NavLink to="/user/data-edit/deactivate">Dezaktywuj</NavLink></li>
                            </ul>
                        </li>
                        <li><a href={"#"} onClick={handleLogout}>Wyloguj</a><img className="login-logo" src={exitIcon}
                                                                                 alt="logo"/></li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Header;
