import React from 'react';
import infoIcon from "../../../assets/icons/info.png";
import {useMutation, useQueryClient} from "react-query";
import {authorizedPatchWithId} from "../../Creator/CreatorFunctions";
import {Select} from "@mantine/core";

const DiscountAmount = ({id, item, url}) => {
    const changeDiscountMutation = useMutation(authorizedPatchWithId)
    const queryClient = useQueryClient()
    const options = Array.from({length: 100}).map((e, i) => {
        return {value: `${i}`, label: `${i}%`}
    })

    return (
        <div className="basket-item">
            <p className="basket-item__left">Wartość rabatu w %</p>

            <div className="tooltip">
                <img src={infoIcon} alt="info icon"/>
                <p className="tooltip__txt">Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit.</p>
            </div>
            <p className="basket-item__right">
                <Select
                    searchable
                    placeholder="Wartość rabatu w %"
                    data={options}
                    onChange={(e) => {
                        changeDiscountMutation.mutate({
                            url: `/api/${url}/${id}/change_discount`,
                            data: {
                                discountAmount: +e,
                            }
                        }, {
                            onSuccess: () => {
                                queryClient.invalidateQueries('cart')
                            }
                        })
                    }
                    }
                    defaultValue={`${item.discountAmount}`}
                    radius="xs"
                    size="md"
                    maxDropdownHeight={400}
                    nothingFound="Brak opcji"
                />
            </p>
        </div>
    );
};

export default DiscountAmount;
