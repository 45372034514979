import React, {useCallback, useEffect, useState} from 'react';
import okIcon from "../../../assets/icons/ok.png";
import eyeIcon from "../../../assets/icons/eye.png";
import deleteIcon from "../../../assets/icons/delete.png";
import {Route, Switch, useHistory, useLocation, useParams} from "react-router-dom";
import {motion} from "framer-motion";
import {useDispatch, useSelector} from "react-redux";
import {userActions} from "../../../store/slice/userSlice";
import {authActions} from "../../../store/slice/authSlice";
import useInput from "../../../hooks/useInput";
import Login from "../../../pages/Auth/Login/Login";
import {Loader} from "@mantine/core";

const UserDataForm = ({isFormSubmitted, success}) => {
    const [pageTitle, setPageTitle] = useState("")
    const location = useLocation();
    const dispatch = useDispatch();
    const locationName = location.pathname.split("/")[3]
    const user = useSelector(state => state.user);
    const auth = useSelector(state => state.auth);
    const [error, setError] = useState(null)
    const [logoutMessage, setLogoutMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    const passRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    const [showPassword, setShowPassword] = useState(false);
    const {
        value: enteredNewEmail,
        isTouched: enteredNewEmailIsTouched,
        hasError: enteredNewEmailHasError,
        enteredValueChangeHandler: enteredNewEmailChangeHandler,
        inputBlurHandler: newEmailBlurHandler,
        reset: resetEnteredNewEmail
    } = useInput(enteredValue => enteredValue.trim().length > 5 && enteredValue.includes("@"));
    const {
        value: enteredRepeatedNewEmail,
        isTouched: enteredRepeatedNewEmailIsTouched,
        hasError: enteredRepeatedNewEmailHasError,
        enteredValueChangeHandler: enteredRepeatedNewEmailChangeHandler,
        inputBlurHandler: newRepeatedEmailBlurHandler,
        reset: resetEnteredRepeatedNewEmail
    } = useInput(enteredValue => enteredValue.trim().length > 5 && enteredValue.includes("@"));
    const {
        value: enteredNewPassword,
        isTouched: enteredNewPasswordIsTouched,
        hasError: enteredNewPasswordHasError,
        enteredValueChangeHandler: enteredNewPasswordChangeHandler,
        inputBlurHandler: newPasswordBlurHandler,
        reset: resetEnteredNewPassword
    } = useInput(enteredValue => passRegex.test(enteredValue));
    const {
        value: enteredNewRepeatedPassword,
        isTouched: enteredNewRepeatedPasswordIsTouched,
        hasError: enteredNewRepeatedPasswordHasError,
        enteredValueChangeHandler: enteredNewRepeatedPasswordChangeHandler,
        inputBlurHandler: newRepeatedPasswordBlurHandler,
        reset: resetEnteredNewRepeatedPassword
    } = useInput(enteredValue => passRegex.test(enteredValue));
    const {
        value: enteredPassword,
        isTouched: enteredPasswordIsTouched,
        hasError: enteredPasswordHasError,
        enteredValueChangeHandler: enteredPasswordChangeHandler,
        inputBlurHandler: PasswordBlurHandler,
        reset: resetEnteredPassword
    } = useInput(enteredValue => enteredValue?.trim().length > 7);

    const isFormValid = enteredNewPassword === enteredNewRepeatedPassword && !enteredNewPasswordHasError && !enteredNewRepeatedPasswordHasError;
    const pathNameCheck = useCallback(() => {
        switch (locationName) {
            case "profile":
                setPageTitle("Edycja danych")
                break;
            case "email" :
                setPageTitle("Zmiana adresu email")
                break;
            case "password" :
                setPageTitle("Zmiana hasła")
                break;
        }
    }, [locationName])

    useEffect(() => {
        pathNameCheck()
    }, [location])

    const handleProfileChanges = (e, data, toChange, method) => {
        e.preventDefault();
        setLogoutMessage(true)
        setIsLoading(true)
        setTimeout(()=> {
            fetch(`${process.env.REACT_APP_API_URL}/api/clients/${user.userId}${toChange}`, {
                method: method,
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": method === "PATCH" ? "application/merge-patch+json" : "application/json",
                    "Authorization": `Bearer ${auth.token}`
                }
            }).then(response => {
                if (response.ok) {
                    isFormSubmitted(true)
                }
                return response.json()
            }).then(data => {
                if (data.detail === "email: This value is not a valid email address.") {
                    setError("Adres email jest nieprawidłowy")
                    return;
                }
                if (data.detail === "Current password is required") {
                    setError("Hasło jest nieprawidłowe")
                    return;
                }
                if (data.detail === "current_password: Current password is not correct.") {
                    setError("Podane aktualne hasło jest nieprawidłowe")
                    return;
                }
                if (data.code === 401 || data.detail === "Not Found") {
                    setError("Coś poszło nie tak, spróbuj ponownie")
                    return;
                }
                if (locationName === "profile") {
                    setIsLoading(false)
                } else {
                    setLogoutMessage(false)
                    dispatch(authActions.logOut())
                }
            })
        }, 3000)

    }

    return (
        <div className="dashboard-container">
            <motion.div
                initial={{opacity: 0, y: 5}}
                animate={{opacity: 1, y: 0}}
                transition={{
                    default: {
                        duration: .2
                    }
                }
                }
                className="dashboard-content">
                <div className="dashboard-content__left">
                    <h2
                        className="content-title">{pageTitle}</h2>
                    <p className="content-description">Bramy garażowe segmentowe znajdują zastosowanie w
                        budownictwie
                        jedno- i wielorodzinnym.</p>
                    <div className="flex gap-40">
                        <div className="content-form content-form__medium">
                            <Route path={"/user/data-edit/email"}>
                                <motion.form
                                    onSubmit={(e) => handleProfileChanges(e, {
                                        email: enteredNewEmail,
                                        current_password: enteredPassword
                                    }, "/change_email", "PATCH")}
                                    initial={{opacity: 0, y: 5}}
                                    animate={{opacity: 1, y: 0}}
                                    transition={{
                                        default: {
                                            duration: .2
                                        }
                                    }
                                    }
                                    className="form client-form">
                                    <div className="client-form__item">
                                        <div className="form--inp">
                                            <label htmlFor="email">Nowy adres email</label>
                                            <input
                                                onChange={enteredNewEmailChangeHandler}
                                                type="email"
                                                id="email"
                                                placeholder="np. abc@abc.com"/>
                                        </div>
                                        <div className="form--inp">
                                            <label htmlFor="repeatedEmail">Powtórz nowy adres email</label>
                                            <input
                                                onChange={enteredRepeatedNewEmailChangeHandler}
                                                type="email"
                                                id="repeatedEmail"
                                                placeholder="np. abc@abc.com"
                                            />
                                        </div>
                                    </div>
                                    {error && <p style={{color: "red", fontWeight: 500}}>{error}</p>}
                                    <div className="underline-form"></div>
                                    <div className="client-form__item client-form__item-last">
                                        <div className="form--inp">
                                            <label htmlFor="currentPassword">Aktualne hasło</label>
                                            <input
                                                onChange={enteredPasswordChangeHandler}
                                                type="password"
                                                id="currentPassword"
                                                placeholder="Wpisz aktualne hasło"/>
                                        </div>
                                    </div>
                                    <div className="client-form__button">
                                        <button
                                            disabled={(enteredNewEmail.trim().length === 0 || enteredRepeatedNewEmail.trim().length === 0) || enteredNewEmail !== enteredRepeatedNewEmail}
                                            type={"submit"}>{isLoading? <Loader color={"white"} size={22}/>: "Zapisz"}<img src={okIcon} alt="background image"/></button>
                                    </div>
                                    {logoutMessage && <p style={{textAlign: "center", marginTop: 20, fontSize: 14}}>Zmiana zakończona sukcesem. Za chwilę zostaniesz wylogowany! <br/> Zaloguj się nowymi danymi!</p>}

                                </motion.form>
                            </Route>
                            <Route path={"/user/data-edit/profile"}>
                                <motion.form
                                    initial={{opacity: 0, y: 5}}
                                    animate={{opacity: 1, y: 0}}
                                    transition={{
                                        default: {
                                            duration: .2
                                        }
                                    }
                                    }
                                    onSubmit={(e) => handleProfileChanges(e, {
                                        name: user.userName,
                                        surname: user.userSurname,
                                        phone: user.userPhone
                                    }, "", "PUT")}
                                    className="form client-form">
                                    <div className="client-form__item">
                                        <div className="form--inp">
                                            <label htmlFor="companyName">Imię</label>
                                            <input
                                                value={user.userName}
                                                onChange={(e) => dispatch(userActions.updateUser({userName: e.target.value}))}
                                                type="text"
                                                id="userName"
                                                placeholder="Zmień imię"/>
                                        </div>
                                        <div className="form--inp">
                                            <label htmlFor="surname">Nazwisko</label>
                                            <input
                                                value={user.userSurname}
                                                onChange={(e) => dispatch(userActions.updateUser({userSurname: e.target.value}))}
                                                type="text"
                                                id="surname"
                                                placeholder="Nazwisko"/>
                                        </div>
                                    </div>
                                    <div className="client-form__item client-form__item-last">
                                        <div className="form--inp">
                                            <label htmlFor="clientPhone">Telefon</label>
                                            <input
                                                value={user.userPhone}
                                                onChange={(e) => dispatch(userActions.updateUser({userPhone: e.target.value}))}
                                                type="text"
                                                id="clientPhone"
                                                placeholder="Numer telefonu"/>
                                        </div>
                                    </div>
                                    <div className="underline-form"></div>
                                    <div className="client-form__item client-form__item-last">
                                        <div className="form--inp">
                                            <label htmlFor="password">Aktualne hasło</label>
                                            <input
                                                onChange={enteredPasswordChangeHandler}
                                                type={showPassword ? "text" : "password"}
                                                id="password"
                                                placeholder="Wprowadź aktualne hasło"/>
                                            <img
                                                onClick={() => setShowPassword(!showPassword)}
                                                className="custom-icons"
                                                src={eyeIcon}
                                                alt="login icon"/>
                                        </div>
                                    </div>
                                    <div className="client-form__button">
                                        <button type={"submit"}>
                                            {isLoading? <Loader color={"white"} size={22}/>: "Zapisz"}
                                            {!isLoading && <img src={okIcon} alt="background image"/>}
                                        </button>
                                    </div>
                                </motion.form>
                            </Route>
                            <Route path={"/user/data-edit/password"}>
                                <motion.form
                                    onSubmit={(e) => handleProfileChanges(e, {
                                        password: enteredNewPassword,
                                        current_password: enteredPassword
                                    }, "/change_password", "PATCH")}
                                    initial={{opacity: 0, y: 5}}
                                    animate={{opacity: 1, y: 0}}
                                    transition={{
                                        default: {
                                            duration: .2
                                        }
                                    }
                                    } className="form client-form">
                                    <div className="client-form__item">
                                        <div className="form--inp">
                                            <label htmlFor="password">Nowe hasło</label>
                                            <input
                                                onChange={enteredNewPasswordChangeHandler}
                                                type="password"
                                                id="password"
                                                placeholder="Wprowadź nowe hasło"/>
                                            {enteredNewPasswordHasError && enteredNewPasswordIsTouched &&
                                                <p style={{color: "red"}}>Hasło musi składać się z min. 8 znaków(w tym cyfra i znak specjalny)</p>}
                                        </div>
                                        <div className="form--inp">
                                            <label htmlFor="new_password">Powtórz nowe hasło</label>
                                            <input
                                                onChange={enteredNewRepeatedPasswordChangeHandler}
                                                type="password"
                                                id="new_password"
                                                placeholder="Wprowadź aktualne hasło"/>
                                        </div>
                                    </div>
                                    {(enteredNewPassword.trim().length === 0 || enteredNewRepeatedPassword.trim().length === 0) || enteredNewPassword !== enteredNewRepeatedPassword &&
                                        <p className="text-center" style={{color: "red", fontWeight: 500}}>Hasła muszą
                                            być takie same!</p>}
                                    <div className="underline-form"></div>
                                    <div className="client-form__item client-form__item-last">
                                        <div className="form--inp">
                                            <label htmlFor="clientPhone">Aktualne hasło</label>
                                            <input
                                                onChange={enteredPasswordChangeHandler}
                                                type="password"
                                                id="clientPhone"
                                                placeholder="Wprowadź katualne hasło"/>
                                        </div>
                                    </div>
                                    {error && <p style={{color: "red", fontWeight: 500}}>{error}</p>}
                                    <div className="client-form__button">
                                        <button
                                            disabled={(enteredNewPassword.trim().length === 0 || enteredNewRepeatedPassword.trim().length === 0) || enteredNewPasswordHasError || enteredNewPassword !== enteredNewRepeatedPassword }
                                            type={"submit"}>
                                            {isLoading? <Loader color={"white"} size={22}/>: "Zapisz"}
                                            <img src={okIcon} alt="background image"/>
                                        </button>
                                    </div>
                                    {logoutMessage && <p style={{textAlign: "center", marginTop: 20, fontSize: 14}}>Po kliknięciu przycisku zapisz zostaniesz wylogowany. <br/> Zaloguj się nowymi danymi!</p>}
                                </motion.form>
                            </Route>
                            <Route path={"/user/data-edit/deactivate"}>
                                <motion.form
                                    initial={{opacity: 0, y: 5}}
                                    animate={{opacity: 1, y: 0}}
                                    transition={{
                                        default: {
                                            duration: .2
                                        }
                                    }
                                    }
                                    action="" className="form client-form">
                                    <div className="client-form__item">
                                        <div className="form--inp">
                                            <label htmlFor="clientPhone">Aktualne hasło</label>
                                            <input type="password" id="clientPhone" placeholder=""/>
                                        </div>
                                    </div>
                                    <div className="client-form__button">
                                        <a
                                            onClick={e => handleProfileChanges(e, {isActive: false}, "/change_active_status", "PATCH")}
                                            style={{maxWidth: "50%"}} href="#" className="btn-danger">
                                            Chcę deaktywować
                                            konto
                                            <img src={deleteIcon} alt="background image"/>
                                        </a>
                                    </div>
                                </motion.form>
                            </Route>
                        </div>
                    </div>
                </div>
                <div className="dashboard-content__right">
                </div>
            </motion.div>
        </div>
    );
};

export default UserDataForm;