import React from 'react';
import {useSelector} from "react-redux";

const Ktm = ({creatorItem}) => {
    const ktm = useSelector(state =>  state.gateCreator.ktm);
    const ktmDescription = useSelector(state =>  state.gateCreator.ktmDescription);
    const ktmAccessories = null;
    return (
        <>
            <div className="summary-box__item">
                <p>KTM</p>
                <p>{creatorItem?.ktmCode}</p>
            </div>
            <div className="summary-box__item">
                <p>KTM - opis</p>
                <p>{creatorItem?.ktmCodeDescription}</p>
            </div>
            {ktmAccessories && <>
                <div className="summary-box__item">
                    <p>KTM (akcesoria)</p>
                    <p>-</p>
                </div>
                <div className="summary-box__item">
                    <p>KTM (akcesoria) - opis</p>
                    <p>-</p>
                </div>
            </>}
        </>
    );
};

export default Ktm;
