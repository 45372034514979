import React, {useState} from 'react';
import CreatorItemDetail from "./CreatorItemDetail/CreatorItemDetail";
import Panel from "./Panel/Panel";
import ExtraAccessories from "./ExtraAccessories/ExtraAccessories";
import Accessories from "./Accessories/Accessories";
import {useDispatch, useSelector} from "react-redux";
import SummaryBox from "../../components/SummaryBox";
import GateCategory from "./GateCategory/GateCategory";
import {motion} from "framer-motion";
import Visualization from "./Visualization/Visualization";
import Drive from "./Leading/Drive";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {authorizedGet, authorizedPutWithId, createComponentItemImg} from "./CreatorFunctions";
import html2canvas from "html2canvas";
import {creatorActions} from "../../store/slice/gateCreatorSlice";
import {Loader} from "@mantine/core";
import SummaryBoxPdf from "./SummaryPDF/SummaryBoxPDF";

const Creator = () => {
    const creatorItem = useSelector(state => state.gateCreator);
    const summaryBox = useSelector(state => state.summaryBox);
    const dispatch = useDispatch();
    let content;
    let title;
    let description;
    const printRef = React.useRef();
    const queryClient = useQueryClient();
    const contents = queryClient.getQueryData("contents")
    const {data: components} = useQuery("components", () => authorizedGet("/api/components"));
    const colors = components?.filter(component => component.name === "Kolor");
    const stampings = components?.filter(component => component.name === "Tłoczenie");
    const [hiddenVisualization, setHiddenVisualization] = useState(false);
    const updateGateMutation = useMutation(authorizedPutWithId, {
        onSuccess: (d) => {
            dispatch(creatorActions.updateCreator(d))
        }
    })

    if (summaryBox.numberOfSteps === 4) {
        switch (summaryBox.currentStep) {
            case 0: {
                title = contents?.find(e => e.slug === "GateTypeTitle")?.content
                description = contents?.find(e => e.slug === "GateTypeDescription")?.content
                content = <><GateCategory updateGateMutation={updateGateMutation}/></>
                break;
            }
            case 1: {
                title = contents?.find(e => e.slug === "CreatorTitle")?.content
                description = contents?.find(e => e.slug === "CreatorDescription")?.content
                content = <><CreatorItemDetail creatorItem={creatorItem} /></>
                break;
            }
            case 2: {
                title = contents?.find(e => e.slug === "PanelTitle")?.content
                description = contents?.find(e => e.slug === "PanelDescription")?.content
                content = <><Panel colors={colors} creatorItem={creatorItem} stampings={stampings}/></>
                break;
            }
            case 3: {
                title = contents?.find(e => e.slug === "ExtraAccessoriesTitle")?.content
                description = contents?.find(e => e.slug === "ExtraAccessoriesDescription")?.content
                content = <><ExtraAccessories/></>
                break;
            }
            case 4: {
                title = contents?.find(e => e.slug === "AccessoriesTitle")?.content
                description = contents?.find(e => e.slug === "AccessoriesDescription")?.content
                content = <><Accessories/></>
                break;
            }
        }
    }
    if (summaryBox.numberOfSteps === 6) {
        switch (summaryBox.currentStep) {
            case 0: {
                title = contents?.find(e => e.slug === "GateTypeTitle")?.content
                description = contents?.find(e => e.slug === "GateTypeDescription")?.content
                content = <><GateCategory updateGateMutation={updateGateMutation}/></>
                break;
            }
            case 1: {
                title = contents?.find(e => e.slug === "CreatorTitle")?.content
                description = contents?.find(e => e.slug === "CreatorDescription")?.content
                content = <><CreatorItemDetail creatorItem={creatorItem} updateGateMutation={updateGateMutation}/></>
                break;
            }
            case 2: {
                title = contents?.find(e => e.slug === "DriveTitle")?.content
                description = contents?.find(e => e.slug === "DriveDescription")?.content
                content = <><Drive/></>
                break;
            }
            case 3: {
                title = contents?.find(e => e.slug === "PanelTitle")?.content
                description = contents?.find(e => e.slug === "PanelDescription")?.content
                content = <><Panel colors={colors} creatorItem={creatorItem} stampings={stampings}/></>
                break;
            }
            case 4: {
                title = contents?.find(e => e.slug === "ExtraAccessoriesTitle")?.content
                description = contents?.find(e => e.slug === "ExtraAccessoriesDescription")?.content
                content = <><ExtraAccessories/></>
                break;
            }
            case 5: {
                title = contents?.find(e => e.slug === "AccessoriesTitle")?.content
                description = contents?.find(e => e.slug === "AccessoriesDescription")?.content
                content = <><Accessories/></>
                break;
            }
            case 6: {
                title = 'Podsumowanie'
                content = <><SummaryBoxPdf/></>
                break;
            }
        }
    }
    const accessoryItemImgMutation = useMutation(createComponentItemImg)

    const handleDownloadImage = async () => {
        const element = printRef.current;
        const canvas = await html2canvas(element, {
            useCORS: true,
            allowTaint: false,
            logging: true,
            letterRendering: 1
        });
        const data = canvas.toDataURL('image/jpg');
        const res = await fetch(data)
        const blob = await res.blob();
        const file = new File([blob], "visualization", {type: "image/png"})
        dispatch(creatorActions.saveRender(file))
        if (file) {
            accessoryItemImgMutation.mutate({
                data: file,
            }, {
                onSuccess: (e) => {
                    updateGateMutation.mutate({
                        url: '/api/gates',
                        data: {
                            render: e.id
                        },
                        id: creatorItem.id
                    })
                }
            })
        }
    };

    return (
        <motion.div
            initial={{opacity: 0, y: 5}}
            animate={{opacity: 1, y: 0}}
            transition={{
                default: {
                    duration: .1
                }
            }
            }
            className="dashboard-container">
            <div className="dashboard-content">
                <div className="dashboard-content__left">
                    <h2 className="content-title">{title}</h2>
                    <p className="content-description">{description}</p>
                    <Visualization printRef={printRef} hiddenVisualization={creatorItem.showVisualization}/>
                    {!creatorItem.showVisualization && content}
                </div>
                <div className="dashboard-content__right">
                    <div className="summary-container">
                        <SummaryBox handleDownloadImage={handleDownloadImage} printRef={printRef}/>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default Creator;
