import React, {useEffect, useState} from 'react';
import "./History.scss";
import "../Cart/Cart.scss";
import {motion} from "framer-motion";
import {useLocation, useParams} from "react-router-dom";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {authorizedPatchWithId} from "../Creator/CreatorFunctions";
import {Loader, Pagination} from "@mantine/core";
import {useDispatch} from "react-redux";
import CustomGateDetails from "./CustomGateDetails";
import CustomAccessories from "./CustomAccessories";
import {sortByDate} from "../../utilis/utilis";
import okIcon from "../../assets/icons/ok.png";

const orderStatus = ["-", "Nowa", "Potwierdzone przez dystrybutora", "Wysłane", "Potwierdzona", "Anulowana", 'Wysłano do dystrybutora'];

const ClientsHistory = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const route = useLocation();
    const queryClient = useQueryClient();
    const content = queryClient.getQueryData("contents")
    const [clickedItem, setClickedItem] = useState(null);
    const [clickedGated, setClickedGated] = useState(null);
    const accessToken = localStorage.getItem("token");
    const updateOrderStatusMutation = useMutation(authorizedPatchWithId)
    const gateOrderHistory = async (page = 1) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api${route.pathname}?page=${page}`, {
            method: "GET",
            headers: {
                'Accept': 'application/ld+json',
                "Authorization": `Bearer ${accessToken}`
            }
        });

        if (!response.ok) {
            throw new Error("Coś poszło nie tak")
        }

        const data = await response.json()

        return {
            data: data['hydra:member'],
            totalItems: data['hydra:totalItems']
        }
    }
    const [page, setPage] = useState(1)
    const {data: order, isLoading, isFetching} = useQuery(["clients_order", page], () => gateOrderHistory(page), {
        retry: false,
        refetchOnWindowFocus: false
    })
    const totalPages = Math.ceil(order?.totalItems / 30);

    useEffect(() => {
        const nextPage = page + 1;

        if (nextPage <= totalPages) {
            queryClient.prefetchQuery(
                ["clients_order", nextPage],
                () => gateOrderHistory(nextPage))
        }
    }, [page, queryClient, totalPages])

    useEffect(() => {
        setPage(1)
    }, [params.type])

    return (
        <div className="history-container dashboard-container dashboard-container--big">
            <div className="dashboard-content">
                <div className="dashboard-content__left">
                    <h2 className="content-title">Zamówienia klientów</h2>

                    <p className="content-description">{content?.find(e => e.slug === "HistoryDescription")?.content}</p>
                    {<div className="content-items content-items__medium" style={{maxWidth: 1300}}>
                        {isFetching && <div style={{padding: 20}}><Loader/></div>}
                        {!isFetching && order?.length === 0 &&
                            <h3 style={{textAlign: "left", margin: "50px 0px", fontSize: 32}}>Historia jest pusta</h3>}
                        {!isFetching && order?.data?.length > 0 && order?.data?.sort((a, b) => sortByDate(a.createdAt, b.createdAt, 'desc'))?.map(item => {
                                return <motion.div
                                    initial={{opacity: 0}}
                                    animate={{opacity: 1}}
                                    transition={{
                                        default: {
                                            duration: .2
                                        }
                                    }
                                    }
                                    key={item.id} className="accordion-container">
                                    <div className="content-accordion__item " style={{alignItems: 'flex-start'}}>
                                        <div className='content-accordion__box'>
                                            <p className="content-accordion__title">Firma</p>
                                            <p className="">{item?.client?.company || "-"}</p>
                                        </div>
                                        <div className='content-accordion__box'>
                                            <p className="content-accordion__title">{item?.orderNumber ? "Nr. zamówienia" : "Nr. oferty"}</p>
                                            <p className="">{item?.orderNumber ? item?.orderNumber : item?.offerNumber || "-"}</p>
                                        </div>
                                        <div className='content-accordion__box'>
                                            <p className="content-accordion__title">Data otrzymania</p>
                                            <p className="">{new Date(item?.createdAt)?.toLocaleDateString()}</p>
                                        </div>
                                        <div className='content-accordion__box'>
                                            <p className="content-accordion__title">IFS</p>
                                            <p className="">{item?.internalNumber || "-"}</p>
                                        </div>
                                        <div className='content-accordion__box'>
                                            <p className="content-accordion__title">Status</p>
                                            <p className="">{orderStatus[item?.status]}</p>
                                        </div>
                                        {params.type === 'orders' && <div className='content-accordion__box'>
                                            <p className="content-accordion__title">Data realizacji</p>
                                            <p className="">{item?.realizationDate ? new Date(item?.realizationDate).toLocaleDateString() : '-'}</p>
                                        </div>}
                                        {params.type === 'offers' && <div className='content-accordion__box'>
                                            <p className="content-accordion__title">Termin ważności</p>
                                            <p className="">{item?.validUntil ? new Date(item?.validUntil).toLocaleDateString() : '-'}</p>
                                        </div>}

                                        <div className='content-accordion__box'>
                                            <p className="content-accordion__title" k>Imię i nazwisko</p>
                                            <p className="">{item?.client?.name + " " + item?.client?.surname || "-"}</p>
                                        </div>
                                        {item?.client?.distributor &&
                                            <div className='content-accordion__box' style={{flex: 1, maxWidth: '10%'}}>
                                                <p className="content-accordion__title">Email</p>
                                                <p className=""><a
                                                    href="#">{item?.client?.email}</a>
                                                </p>
                                            </div>}
                                        {item?.offer?.offerNumber &&
                                            <div className='content-accordion__box' style={{flex: 1, maxWidth: '10%'}}>
                                                <p className="content-accordion__title">Oferta</p>
                                                <p className="">{item?.offer?.offerNumber || "-"}</p>
                                            </div>}
                                        <div style={{flex: 1, maxWidth: '10%'}}></div>
                                        {!item?.offer?.offerNumber && <div className='content-accordion__box'
                                                                           style={{flex: 1, maxWidth: '10%'}}></div>}
                                    </div>
                                    <div className="content-accordion__item--bottom">
                                        <div className='content-accordion__box'>
                                            <p className="content-accordion__title">Firma klienta</p>
                                            <p className="">{item?.receiverCompany ? item?.receiverCompany : item?.client.company ? item.client.company : "-"}</p>
                                        </div>
                                        <div className='content-accordion__box'>
                                            <p className="content-accordion__title">Imię i nazwisko klienta</p>
                                            <p className="">{item?.receiverName ? item?.receiverName : item?.client?.name + " " + item?.client?.surname || "-"}</p>
                                        </div>
                                        <div className='content-accordion__box'>
                                            <p className="content-accordion__title">Nr.
                                                wew. {params.type === 'orders' ? 'zamówienia' : 'oferty'}</p>
                                            <p className="">{item?.clientNumber || "-"}</p>
                                        </div>
                                        <div className='content-accordion__box'>
                                            <p className="content-accordion__title">Adres klienta</p>
                                            <p className="">{item?.receiverAddress ? item?.receiverAddress : item?.client?.street || "-"}</p>
                                        </div>
                                        <div className='content-accordion__box'>
                                            <p className="content-accordion__title">Kod pocztowy klienta</p>
                                            <p className="">{item?.receiverZipCode ? item?.receiverZipCode : item?.client?.zipCode || "-"}</p>
                                        </div>
                                        <div className='content-accordion__box'>
                                            <p className="content-accordion__title">Miasto klienta</p>
                                            <p className="">{item?.receiverCity ? item?.receiverCity : item?.client?.city || "-"}</p>
                                        </div>
                                        <div className='content-accordion__box'>
                                            <p className="content-accordion__title">Państwo klienta</p>
                                            <p className="">{item?.receiverCountry ? item?.receiverCountry : item?.client?.country || "-"}</p>
                                        </div>
                                        <div className='content-accordion__box'>
                                            <p className="content-accordion__title">Telefon klienta</p>
                                            <p className="">{item?.receiverPhone ? item?.receiverPhone : item?.client?.phone || "-"}</p>
                                        </div>
                                        <div className='content-accordion__box'>
                                            <p className="content-accordion__title">Email klienta</p>
                                            <p className="">{item?.receiverEmail ? item?.receiverEmail : item?.client?.email || "-"}</p>
                                        </div>
                                    </div>
                                    {<motion.div
                                        initial={{opacity: 0, y: 5}}
                                        animate={{opacity: 1, y: 0}}
                                        transition={{
                                            delay: .2,
                                            default: {
                                                duration: .1
                                            }
                                        }
                                        }
                                        className="content-accordion-expand">
                                        <div className="basket-container">
                                            <div className="basket-left">
                                                <div className="basket-box">
                                                    {item?.offerData && item?.offerData?.items.map((gate, index) => {
                                                        return (
                                                            <CustomGateDetails gate={gate} item={item} index={index}/>
                                                        )
                                                    })}

                                                    {item?.orderData && item?.orderData?.items.map((gate, index) => {
                                                        return (
                                                            <CustomGateDetails gate={gate} item={item} index={index}/>
                                                        )
                                                    })}
                                                    {item?.orderData?.items.length === 0 && item?.orderData?.accessories?.length > 0 &&
                                                        <CustomAccessories item={item}/>
                                                    }
                                                    {item?.offerData?.items.length === 0 && item?.offerData?.accessories?.length > 0 &&
                                                        <CustomAccessories item={item}/>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <p>Kalkulacja dystrybutora:</p>
                                        <div className="content-accordion__item " style={{padding: 0}}>
                                            <div className='content-accordion__box'>
                                                <p className="content-accordion__title">Wartość netto</p>
                                                <p className="">{(item?.orderData?.basePriceData?.regularNetPrice / 100).toFixed(2) + " zł" || "-"}</p>
                                            </div>
                                            <div className='content-accordion__box'>
                                                <p className="content-accordion__title">Wartość rabatu</p>
                                                <p>{Math.round(((1 - (item?.orderData?.basePriceData?.netPrice / item?.orderData?.basePriceData?.regularNetPrice))).toFixed(2) * 100) + " %" || "-"}</p>
                                            </div>
                                            <div className='content-accordion__box'>
                                                <p className="content-accordion__title">Wartość netto (po rabacie)</p>
                                                <p className="">{(item?.orderData?.basePriceData?.netPrice / 100).toFixed(2) + " zł" || "-"}</p>
                                            </div>
                                        </div>
                                        <p>Kalkulacja klienta:</p>
                                        <div className="content-accordion__item " style={{padding: 0}}>
                                            <div className='content-accordion__box'>
                                                <p className="content-accordion__title">Wartość netto</p>
                                                <p className="">{(item?.orderData?.priceData?.regularNetPrice / 100).toFixed(2) + " zł" || "-"}</p>
                                            </div>
                                            <div className='content-accordion__box'>
                                                <p className="content-accordion__title">Wartość rabatu</p>
                                                <p>{Math.round(((1 - (item?.orderData?.priceData?.netPrice / item?.orderData?.priceData?.regularNetPrice))) * 100) + " %" || "-"}</p>
                                            </div>
                                            <div className='content-accordion__box'>
                                                <p className="content-accordion__title">Wartość netto (po rabacie)</p>
                                                <p className="">{(item?.orderData?.priceData?.netPrice / 100).toFixed(2) + " zł" || "-"}</p>
                                            </div>
                                        </div>
                                        {item.status === 6 && <div className={'buttons-box'} style={{width: '100%'}}>
                                            <button
                                                onClick={() => {
                                                    updateOrderStatusMutation.mutate({
                                                        url: `/api/orders/${item.id}`,
                                                        data: {
                                                            status: 2
                                                        }
                                                    }, {
                                                        onSuccess: () => {
                                                            queryClient.invalidateQueries('clients_order')
                                                        }
                                                    })
                                                }}
                                                style={{marginLeft: 'auto', padding: '10px 20px', maxWidth: 130}}
                                                className="main-button main-button--accept">Zatwierdź<img
                                                src={okIcon} alt="background image"/></button>
                                        </div>}
                                    </motion.div>}
                                </motion.div>
                            }
                        )}
                        {totalPages > 1 && <div className={'pb-24'}>
                            <Pagination total={totalPages} onChange={setPage}/>
                        </div>}
                    </div>}
                </div>
            </div>
        </div>
    );
};

export default ClientsHistory;
