import React, {useEffect, useState} from 'react';
import './NewClientAdd.scss';
import addIcon from '../../../assets/icons/add.png';
import {Transition} from "react-transition-group";
import {useHistory, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import useInput from "../../../hooks/useInput";
import {useClipboard} from '@mantine/hooks';
import {useQuery, useQueryClient} from "react-query";
import {authorizedGet, authorizedGetById} from "../../Creator/CreatorFunctions";
import {Loader} from "@mantine/core";

const NewClientAdd = () => {
    const [pin, setPin] = useState("");
    const history = useHistory();
    const token = localStorage.getItem("token")
    const user = useSelector(state => state.user);
    const clipboard = useClipboard({timeout: 500});
    const queryClient = useQueryClient();
    const content = queryClient.getQueryData("contents");
    const params = useParams();
    const {
        data: client,
        isFetching,
        isLoading
    } = useQuery(["client", params[0]], () => authorizedGetById(`/api/clients`, params[0] !== "new" ? params[0] : null), {
        keepPreviousData: true,
        refetchOnWindowFocus: false
    })
    const {
        data: gateTypes,
        isLoading: isGateTypesLoading
    } = useQuery(['gateTypes', client], () => authorizedGet('/api/gate_types?pagination=false'), {
        placeholderData: [],
        refetchOnWindowFocus: false,
    })

    useEffect(() => {
        if (client !== undefined) {
            enteredCompanyShortInit(client?.companyShort)
            enteredCompanyInit(client?.company)
            enteredNameInit(client?.name)
            enteredSurnameInit(client?.surname)
            enteredPhoneInit(client?.phone)
            enteredCityInit(client?.city)
            enteredEmailInit(client?.email)
            enteredInternalNumberInit(client?.internalNumber)
            enteredZipCodeInit(client?.zipCode)
            enteredVatInit(client?.vatNumber)
            enteredStreetInit(client?.street)
            enteredCountryInit(client?.country)
            enteredProvinceInit(client?.province)
            enteredAccessoriesDiscountInit(client?.accessoriesDiscount > 0 ? client?.accessoriesDiscount : 0)
        }
    }, [client])

    const [discounts, setDiscounts] = useState([])
    const {
        value: enteredCompanyShort,
        isTouched: enteredCompanyShortIsTouched,
        hasError: enteredCompanyShortHasError,
        enteredValueChangeHandler: enteredCompanyShortChangeHandler,
        inputBlurHandler: companyShortBlurHandler,
        reset: resetEnteredCompanyShort,
        enteredValueSetInitial: enteredCompanyShortInit
    } = useInput(() => true, client?.companyShort);

    const {
        value: enteredCompany,
        isTouched: enteredCompanyIsTouched,
        hasError: enteredCompanyHasError,
        enteredValueChangeHandler: enteredCompanyChangeHandler,
        inputBlurHandler: companyBlurHandler,
        reset: resetEnteredCompany,
        enteredValueSetInitial: enteredCompanyInit
    } = useInput(() => true, client?.company);

    const {
        value: enteredNip,
        isTouched: enteredNipIsTouched,
        hasError: enteredNipHasError,
        enteredValueChangeHandler: enteredNipChangeHandler,
        inputBlurHandler: nipBlurHandler,
        reset: resetEnteredNip,
        enteredValueSetInitial: enteredVatInit
    } = useInput(() => true, client?.vatNumber);

    const {
        value: enteredName,
        isTouched: enteredNameIsTouched,
        hasError: enteredNameHasError,
        enteredValueChangeHandler: enteredNameChangeHandler,
        inputBlurHandler: nameBlurHandler,
        reset: resetEnteredName,
        enteredValueSetInitial: enteredNameInit
    } = useInput((enteredValue) => enteredValue?.trim().length > 0, client?.name);

    const {
        value: enteredSurname,
        isTouched: enteredSurnameIsTouched,
        hasError: enteredSurnameHasError,
        enteredValueChangeHandler: enteredSurnameChangeHandler,
        inputBlurHandler: surnameBlurHandler,
        reset: resetEnteredSurname,
        enteredValueSetInitial: enteredSurnameInit
    } = useInput((enteredValue) => enteredValue.trim().length > 0);

    const {
        value: enteredInternalNumber,
        isTouched: enteredInternalNumberIsTouched,
        hasError: enteredInternalNumberHasError,
        enteredValueChangeHandler: enteredInternalNumberChangeHandler,
        inputBlurHandler: internalNumberBlurHandler,
        reset: resetEnteredInternalNumber,
        enteredValueSetInitial: enteredInternalNumberInit
    } = useInput(() => true);

    const {
        value: enteredEmail,
        isTouched: enteredEmailIsTouched,
        hasError: enteredEmailHasError,
        enteredValueChangeHandler: enteredEmailChangeHandler,
        inputBlurHandler: emailBlurHandler,
        reset: resetEnteredEmail,
        enteredValueSetInitial: enteredEmailInit
    } = useInput((enteredValue) => enteredValue.includes("@"));

    const {
        value: enteredPhone,
        isTouched: enteredPhoneIsTouched,
        hasError: enteredPhoneHasError,
        enteredValueChangeHandler: enteredPhoneChangeHandler,
        inputBlurHandler: phoneBlurHandler,
        reset: resetEnteredPhone,
        enteredValueSetInitial: enteredPhoneInit
    } = useInput(() => true);

    const {
        value: enteredDistributor,
        isTouched: enteredDistributorIsTouched,
        hasError: enteredDistributorHasError,
        enteredValueChangeHandler: enteredDistributorChangeHandler,
        inputBlurHandler: distributorBlurHandler,
        reset: resetEnteredDistributor,
        enteredValueSetInitial: enteredDistributorInit
    } = useInput(() => true);
    const {
        value: enteredCity,
        isTouched: enteredCityIsTouched,
        hasError: enteredCityHasError,
        enteredValueChangeHandler: enteredCityChangeHandler,
        inputBlurHandler: cityBlurHandler,
        reset: resetEnteredCity,
        enteredValueSetInitial: enteredCityInit
    } = useInput(() => true);

    const {
        value: enteredCountry,
        isTouched: enteredCountryIsTouched,
        hasError: enteredCountryHasError,
        enteredValueChangeHandler: enteredCountryChangeHandler,
        inputBlurHandler: countryBlurHandler,
        reset: resetEnteredCountry,
        enteredValueSetInitial: enteredCountryInit
    } = useInput(() => true);

    const {
        value: enteredAccessoriesDiscount,
        isTouched: enteredAccessoriesDiscountIsTouched,
        hasError: enteredAccessoriesDiscountHasError,
        enteredValueChangeHandler: enteredAccessoriesDiscountChangeHandler,
        inputBlurHandler: accessoriesDiscountBlurHandler,
        reset: resetEnteredAccessoriesDiscount,
        enteredValueSetInitial: enteredAccessoriesDiscountInit
    } = useInput(() => true);

    const {
        value: enteredProvince,
        isTouched: enteredProvinceIsTouched,
        hasError: enteredProvinceHasError,
        enteredValueChangeHandler: enteredProvinceChangeHandler,
        inputBlurHandler: provinceBlurHandler,
        reset: resetEnteredProvince,
        enteredValueSetInitial: enteredProvinceInit
    } = useInput(() => true);

    const {
        value: enteredStreet,
        isTouched: enteredStreetIsTouched,
        hasError: enteredStreetHasError,
        enteredValueChangeHandler: enteredStreetChangeHandler,
        inputBlurHandler: streetBlurHandler,
        reset: resetEnteredStreet,
        enteredValueSetInitial: enteredStreetInit
    } = useInput(() => true);

    const {
        value: enteredZipCode,
        isTouched: enteredZipCodeIsTouched,
        hasError: enteredZipCodeHasError,
        enteredValueChangeHandler: enteredZipCodeChangeHandler,
        inputBlurHandler: zipCodeBlurHandler,
        reset: resetEnteredZipCode,
        enteredValueSetInitial: enteredZipCodeInit
    } = useInput(() => true);

    const isFormValid = (!enteredEmailHasError && enteredEmailIsTouched) && (!enteredNameHasError && enteredNameIsTouched) && (!enteredSurnameHasError && enteredSurnameIsTouched);

    useEffect(() => {
        if (!!client?.discounts?.length) {
            setDiscounts(client?.discounts)
        } else {
            const gateTypesDiscounts = gateTypes.map(gT => {
                return {
                    gateType: {id: gT.id},
                    discount: 0,
                    name: gT.name
                }
            })
            if (gateTypesDiscounts) {
                setDiscounts(gateTypesDiscounts)
            }
        }

    }, [gateTypes, client])

    const pinGenerateHandler = async () => {
        if (pin.trim().length > 0) return;

        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/distributor_pins`, {
            method: "POST",
            body: JSON.stringify({
                distributor: user.userId,
                pin: ""
            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
        const data = await response.json()

        setPin(data.pin)
    }


    const addNewClientHandler = async (e) => {
        e.preventDefault();
        if (params[0] === "new") {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/clients`, {
                    method: "POST",
                    body: JSON.stringify({
                        company: enteredCompany,
                        companyShort: enteredCompanyShort,
                        name: enteredName,
                        surname: enteredSurname,
                        email: enteredEmail,
                        internalNumber: user.internalNumber,
                        street: enteredStreet,
                        city: enteredCity,
                        zipCode: enteredZipCode,
                        phone: enteredPhone,
                        country: enteredCountry,
                        province: enteredProvince,
                        vatNumber: enteredNip,
                        accessoriesDiscount: +enteredAccessoriesDiscount,
                        isActive: false,
                        distributor: user.userId,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                })

                if (!response.ok) {
                    throw new Error("Coś poszło nie tak!")
                }

                history.push("/clients")
            } catch (e) {
                console.log(e)
            }
        } else {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/clients/${params[0]}`, {
                method: "PUT",
                body: JSON.stringify({
                    company: enteredCompany,
                    companyShort: enteredCompanyShort,
                    name: enteredName,
                    surname: enteredSurname,
                    email: enteredEmail,
                    internalNumber: user.internalNumber,
                    street: enteredStreet,
                    city: enteredCity,
                    zipCode: enteredZipCode,
                    phone: enteredPhone,
                    vatNumber: enteredNip,
                    country: enteredCountry,
                    province: enteredProvince,
                    isActive: client?.isActive,
                    distributor: user.userId,
                    accessoriesDiscount: +enteredAccessoriesDiscount,
                    discounts: discounts.map(d => {
                        return {
                            gateType: d.gateType.id,
                            discount: d.discount
                        }
                    }),
                }),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })

            if (!response.ok) {
                throw new Error("Coś poszło nie tak put klient!")
            }

            history.push("/clients")

        }

    }

    return (
        <div className="dashboard-container">
            <div className="dashboard-content">
                <div className="dashboard-content__left">
                    <h2 className="content-title">{params[0] === "new" ? "Dodawanie nowego" : "Edycja"} klienta</h2>
                    <p className="content-description">{content?.find(e => e.slug === "NewClientDescription")?.content}</p>
                    {isFetching && <div style={{width: "100%", textAlign: "center", padding: 20}}><Loader/></div>}
                    {!isFetching && <div className="flex gap-40">
                        <div className="content-form content-form__medium">
                            <form onSubmit={addNewClientHandler} className="form client-form">
                                <div className="client-form__item">
                                    <div className="form--inp">
                                        <label htmlFor="companyName">Nazwa firmy</label>
                                        <input
                                            onChange={enteredCompanyChangeHandler}
                                            onBlur={companyBlurHandler}
                                            value={enteredCompany}
                                            type="text" id="companyName"
                                            placeholder="Nazwa firmy"/>
                                    </div>
                                    <div className="form--inp">
                                        <label htmlFor="company_short">Nazwa skrócona firmy</label>
                                        <input
                                            onChange={enteredCompanyShortChangeHandler}
                                            onBlur={companyShortBlurHandler}
                                            value={enteredCompanyShort}
                                            type="text"
                                            id="company_short"
                                            placeholder="Nazwa skórcona firmy"
                                        />
                                    </div>
                                </div>
                                <div className="client-form__item">
                                    <div className="form--inp required">
                                        <label htmlFor="clientName ">
                                            Imię<span style={{color: "red"}}>*</span>
                                        </label>
                                        <input
                                            onChange={enteredNameChangeHandler}
                                            onBlur={nameBlurHandler}
                                            value={enteredName}
                                            type="text"
                                            id="clientName"
                                            placeholder="Imię"/>
                                    </div>
                                    <div className="form--inp">
                                        <label htmlFor="clientSurname">
                                            Nazwisko<span style={{color: "red"}}>*</span>
                                        </label>
                                        <input
                                            onChange={enteredSurnameChangeHandler}
                                            onBlur={surnameBlurHandler}
                                            value={enteredSurname}
                                            type="text"
                                            id="clientSurname"
                                            placeholder="Nazwisko"/>
                                    </div>
                                </div>
                                <div className="client-form__item">
                                    <div className="form--inp">
                                        <label htmlFor="clientEmail">
                                            E-mail<span style={{color: "red"}}>*</span>
                                        </label>
                                        <input
                                            onChange={enteredEmailChangeHandler}
                                            onBlur={emailBlurHandler}
                                            value={enteredEmail}
                                            type="text"
                                            id="clientEmail"
                                            placeholder="E-mail"/>
                                    </div>
                                    <div className="form--inp">
                                        <label htmlFor="companyNIP">NIP firmy</label>
                                        <input
                                            onChange={enteredNipChangeHandler}
                                            onBlur={nipBlurHandler}
                                            value={enteredNip}
                                            type="text"
                                            minLength={10}
                                            maxLength={10}
                                            id="companyNIP"
                                            placeholder="NIP firmy"/>
                                    </div>
                                </div>
                                <div className="client-form__item">
                                    <div className="form--inp">
                                        <label htmlFor="name">Ulica</label>
                                        <input
                                            onChange={enteredStreetChangeHandler}
                                            onBlur={streetBlurHandler}
                                            value={enteredStreet}
                                            type="text"
                                            id="street"
                                            placeholder="np. ul. Miła"
                                        />
                                    </div>
                                    <div className="form--inp">
                                        <label htmlFor="surname">Miasto</label>
                                        <input
                                            onChange={enteredCityChangeHandler}
                                            onBlur={cityBlurHandler}
                                            value={enteredCity}
                                            type="text"
                                            id="city"
                                            placeholder="np. Warszawa"
                                        />
                                    </div>
                                </div>
                                <div className="client-form__item">
                                    <div className="form--inp">
                                        <label htmlFor="surname">Kod pocztowy</label>
                                        <input
                                            onChange={enteredZipCodeChangeHandler}
                                            onBlur={zipCodeBlurHandler}
                                            value={enteredZipCode}
                                            type="text"
                                            id="city"
                                            placeholder="np. 00-100"
                                        />
                                    </div>
                                    <div className="form--inp">
                                        <label htmlFor="clientPhone">Województwo</label>
                                        <input
                                            onChange={enteredProvinceChangeHandler}
                                            onBlur={provinceBlurHandler}
                                            value={enteredProvince}
                                            type="text"
                                            id="province"
                                            placeholder="Województwo"/>
                                    </div>
                                </div>
                                <div className="client-form__item">
                                    <div className="form--inp">
                                        <label htmlFor="surname">Państwo</label>
                                        <input
                                            onChange={enteredCountryChangeHandler}
                                            onBlur={countryBlurHandler}
                                            value={enteredCountry}
                                            type="text"
                                            id="country"
                                            placeholder="Państwo"
                                        />
                                    </div>
                                    <div className="form--inp">
                                        <label htmlFor="clientPhone">Numer telefonu</label>
                                        <input
                                            onChange={enteredPhoneChangeHandler}
                                            onBlur={phoneBlurHandler}
                                            value={enteredPhone}
                                            type="text"
                                            id="clientPhone"
                                            placeholder="Numer telefonu"/>
                                    </div>
                                </div>
                                <div className="client-form__item"
                                     style={
                                         {
                                             display: 'grid',
                                             gridTemplateColumns: '1fr 1fr',
                                             gap: '0 20px'
                                         }
                                     }>
                                    {discounts.map((gateType, i) => {
                                        const gateName = gateTypes.find(e => e.id === gateType.gateType.id)?.name
                                        return (
                                            <div className="form--inp" style={{width: '100%'}}>
                                                <label htmlFor="clientPhone">{gateName}</label>
                                                <input
                                                    type="number"
                                                    value={gateType.discount > 0 ? gateType.discount : undefined}
                                                    onChange={e => {
                                                        let tempDisc = [...discounts]
                                                        tempDisc[i] = {...tempDisc[i], discount: +e.target.value}
                                                        setDiscounts(tempDisc)
                                                    }}
                                                    id={`gateDiscount-${gateType.id}`}
                                                    placeholder="Wysokość rabatu w %"
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="client-form__item">
                                    <div className="form--inp" style={{paddingRight: 10}}>
                                        <label htmlFor="surname">Akcesoria - wartość rabatu
                                            w %</label>
                                        <input
                                            onChange={enteredAccessoriesDiscountChangeHandler}
                                            onBlur={accessoriesDiscountBlurHandler}
                                            value={enteredAccessoriesDiscount > 0 ? enteredAccessoriesDiscount : undefined}
                                            type="number"
                                            id="accessoriesDiscount"
                                            placeholder="Wysokość rabatu w %"
                                        />
                                    </div>
                                </div>
                                <div className="client-form__button">
                                    {params[0] === "new" &&
                                        <button type={"button"} disabled={pin.length > 0} onClick={pinGenerateHandler}
                                                id="btn__pin">
                                            Generuj PIN
                                        </button>}
                                    <button
                                        style={{marginLeft: "auto"}}
                                        disabled={!isFormValid && params[0] === "new"}
                                        type={"submit"}>
                                        {params[0] === "new" ? "Dodaj" : "Edytuj"} klienta
                                        <img src={addIcon} alt="background image"/>
                                    </button>
                                </div>
                                {pin.length > 0 &&
                                    <Transition in={pin.length > 0} timeout={300}>
                                        <div className={`client-form__pin ${pin.length > 0 ? "show" : null}`}>
                                            <input id="kt_clipboard_1" type="text" className="form-control"
                                                   placeholder=""
                                                   value={pin}/>

                                            <button
                                                className="btn btn-light-primary"
                                                type="button"
                                                onClick={() => {
                                                    clipboard.copy(pin)
                                                }}>
                                                {clipboard.copied ? 'Skopiowano' : 'Kopiuj'}
                                            </button>
                                        </div>
                                    </Transition>}
                            </form>
                        </div>
                    </div>}
                </div>
                <div className="dashboard-content__right"/>
            </div>
        </div>
    );
};

export default NewClientAdd;
