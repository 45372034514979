import React from 'react';
import {motion} from "framer-motion";
import {useDispatch, useSelector} from "react-redux";
import {creatorActions} from "../../../store/slice/gateCreatorSlice";
import {Link, useHistory, useLocation} from "react-router-dom";
import {summaryBoxActions} from "../../../store/slice/summarySlice";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {Loader} from '@mantine/core';
import {authorizedGet, authorizedPost} from "../CreatorFunctions";
import {visualizationActions} from "../../../store/slice/visualizationSlice";

const GateCategory = ({updateGateMutation}) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const creatorItem = useSelector(state => state.gateCreator);
    const history = useHistory();
    const client = useQueryClient();
    const {data: gateTypes, isLoading} = useQuery("gateTypes", () => authorizedGet('/api/gate_types?isHidden=false'));
    const location = useLocation();
    const isMiniCreator = location.pathname.startsWith('/mini-creator');
    const createGateMutation = useMutation(authorizedPost);
    const updateCreator = (gate) => {
        dispatch(creatorActions.updateCreator({chosenGate: gate}))
        dispatch(visualizationActions.addGateRender(gate?.render?.contentUrl))
        createGateMutation.mutate({
                url: "/api/gates",
                data: {
                    gateType: gate.id,
                    client: user.userId
                }
            }, {
                onSuccess: (d) => {
                    dispatch(creatorActions.updateCreator(d))
                    dispatch(summaryBoxActions.updateNumberOfSteps(6))
                    dispatch(summaryBoxActions.nextCurrentStep())
                }
            }
        )
    }
    const goToAccessories = () => {
        dispatch(summaryBoxActions.updateCurrentStep(4))
    }

    return (
        <div className="flex gap-40">
            <div className="content-items p-24">
                {isLoading && <Loader/>}
                {gateTypes && gateTypes?.length > 0 && gateTypes.sort((a, b) => a.gateTypeOrder - b.gateTypeOrder).map(gate => {
                    return (
                        <motion.div
                            initial={{
                                opacity: 0
                            }}
                            animate={{
                                opacity: 1
                            }}
                            className="content-item content-item1" key={gate.id}
                            style={{
                                backgroundImage: ` linear-gradient(black, white),url(${process.env.REACT_APP_API_UPLOADS + gate?.image?.contentUrl})`,
                                backgroundSize: "cover",
                                backgroundBlendMode: "screen"
                            }}>

                            <p className="content-item__title">{gate.name}</p>
                            <button className="content-item__link"
                                    onClick={() => {
                                        updateCreator(gate)
                                    }}
                            />
                        </motion.div>
                    )
                })}
            </div>
            {!isMiniCreator && <div className="dashboard-content__extra p-24">
                <div className="content-item content-item1 content-accessory">
                    <p className="content-item__title">Akcesoria</p>
                    <Link
                        onClick={goToAccessories}
                        className="content-item__link"
                        to={`/${isMiniCreator ? 'mini-creator' :'creator'}/step/4`}/>
                </div>
            </div>}
        </div>
    );
};

export default GateCategory;
