import React, {useState} from 'react';
import {useMutation, useQuery, useQueryClient} from "react-query";
import {findGateById} from "./HistoryFunctions";
import {Loader} from "@mantine/core";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {summaryBoxActions} from "../../store/slice/summarySlice";
import {creatorActions} from "../../store/slice/gateCreatorSlice";
import copyIcon from "../../assets/icons/copy.png";
import downloadIcon from "../../assets/icons/download.png";
import {authorizedPatchWithId, authorizedPost, authorizedPutWithId} from "../Creator/CreatorFunctions";
import {useDispatch, useSelector} from "react-redux";
import arrowUpIcon from "../../assets/icons/expanded.png";

const CustomGateDetails = ({gate, item, index}) => {
        const accessToken = localStorage.getItem("token");
        const history = useHistory();
        const params = useParams();
        const location = useLocation()
        const queryClient = useQueryClient();
        const dispatch = useDispatch();
        const user = useSelector(state => state.user);
        const [clickedItem, setClickedItem] = useState(null);
        const {
            data: gateDetailed,
            isLoading
        } = useQuery(["gateDetails", gate.id], () => findGateById(accessToken, gate.gate), {
            keepPreviousData: true,
            refetchOnWindowFocus: false
        })

        const addGateAccessoriesToCartMutation = useMutation(authorizedPatchWithId);
        const updateGateMutation = useMutation(authorizedPutWithId)
        const createGateMutation = useMutation(authorizedPost, {
            onSuccess: (e) => {
                setTimeout(() => {
                    createGateMutation.reset()
                }, 2000)
            },
            onError: (e) => {
                setTimeout(() => {
                    createGateMutation.reset()
                }, 2000)
            }
        })
        const getPDF = async (url) => {

            try {
                let httpClient = new XMLHttpRequest();
                let pdfLink = `${process.env.REACT_APP_API_URL}/api/orders/${item.id}/${url}`;
                httpClient.open('get', pdfLink, true);
                httpClient.setRequestHeader("Authorization", `Bearer ${accessToken}`)
                httpClient.responseType = "blob";
                httpClient.onload = function () {
                    const file = new Blob([httpClient.response], {type: 'application/pdf'});
                    const fileURL = URL.createObjectURL(file);
                    const link = document.createElement("a");
                    link.href = fileURL;
                    link.download = `${params.type === "offers" ? item.offerNumber : item.orderNumber}.pdf`;
                    link.click();
                    // document.body.removeChild(link);
                    URL.revokeObjectURL(fileURL);
                };
                httpClient.send();
            } catch (e) {
                console.log(e);
            }
        }

        return (
            <div style={{marginBottom: 20}}>
                {isLoading &&
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 30,
                        width: "100%"
                    }}><Loader/></div>}
                <div className="basket-left">
                    <div className="basket-box">
                        <div className="basket-item">
                            <p className="basket-item__left basket-item__left--round">{index + 1}</p>
                            <p className="basket-item__right">
                                <div
                                    className="accordion-icons flex">
                                    <img
                                        className={`accordion-icons__expand ${clickedItem !== item.id ? "show" : ""}`}
                                        onClick={(e) => {
                                            setClickedItem(prev => {
                                                if (prev === item.id) {
                                                    return null
                                                } else {
                                                    return item.id
                                                }
                                            })
                                        }} src={arrowUpIcon}
                                        alt="accordion icon"/>
                                </div>
                            </p>
                        </div>
                    </div>
                    <div className="basket-box">
                        <div className="basket-item">
                            <p className="basket-item__left">KTM</p>
                            <p className="basket-item__right">{gateDetailed?.ktmCode}</p>
                        </div>
                    </div>
                    <div className="basket-box">
                        <div className="basket-item">
                            <p className="basket-item__left" style={{whiteSpace: 'nowrap'}}>KTM - opis</p>
                            <p className="basket-item__right">{gateDetailed?.ktmCodeDescription}</p>
                        </div>
                    </div>
                    <div className="basket-box">
                        <div className="basket-item">
                            <p className="basket-item__left" style={{whiteSpace: 'nowrap'}}>Ilość</p>
                            <p className="basket-item__right">{gate?.quantity || '-'}</p>
                        </div>
                    </div>
                    {clickedItem === item.id && <>
                        <div className="basket-box">
                            <div className="basket-item">
                                <p className="basket-item__left">Typ bramy</p>
                                <p className="basket-item__right">{gateDetailed?.gateType?.name}</p>
                            </div>
                        </div>
                        <div className="basket-box">
                            {!!gateDetailed?.width?.value && <div className="basket-item">
                                <p className="basket-item__left">Szerokość</p>
                                <p className="basket-item__right">{gateDetailed?.width?.value} mm</p>
                            </div>}
                            {!!gateDetailed?.height?.value && <div className="basket-item">
                                <p className="basket-item__left">Wysokość</p>
                                <p className="basket-item__right">{gateDetailed?.height?.value} mm</p>
                            </div>}
                            {!!gateDetailed?.depth && <div className="basket-item">
                                <p className="basket-item__left">Głębokość</p>
                                <p className="basket-item__right">{gateDetailed?.depth} mm</p>
                            </div>}
                            {!!gateDetailed?.rightArea && <div className="basket-item">
                                <p className="basket-item__left">Przestrzeń boczna z prawej</p>
                                <p className="basket-item__right">{gateDetailed?.rightArea} mm</p>
                            </div>}
                            {!!gateDetailed?.leftArea && <div className="basket-item">
                                <p className="basket-item__left">Przestrzeń boczna z lewej</p>
                                <p className="basket-item__right">{gateDetailed?.leftArea} mm</p>
                            </div>}
                            {!!gateDetailed?.lintel?.value && <div className="basket-item">
                                <p className="basket-item__left">Nadproże</p>
                                <p className="basket-item__right">{gateDetailed?.lintel?.value} mm</p>
                            </div>}
                            {!!gateDetailed?.drive && <div className="basket-item">
                                <p className="basket-item__left">Napęd</p>
                                <p className="basket-item__right">{gateDetailed?.drive?.name}</p>
                            </div>}
                            {!!gateDetailed?.leading?.name && <div className="basket-item">
                                <p className="basket-item__left">Prowadzenie</p>
                                <p className="basket-item__right">{gateDetailed?.leading?.name}</p>
                            </div>}
                            {gateDetailed?.componentItems?.length > 0 && gateDetailed?.componentItems.map(comp => {
                                return (
                                    <div className="basket-item">
                                        <p className="basket-item__left">{comp?.component?.name}</p>
                                        <p className="basket-item__right">{comp?.name}</p>
                                    </div>
                                )
                            })}
                        </div>

                        <div className="basket-box">
                            <div className="basket-item">
                                <p className="basket-item__left">Akcesoria budowy bramy:</p>
                            </div>
                            {gateDetailed?.gateAccessories?.map(i => {
                                return (
                                    <div className="basket-item">
                                        <p className="basket-item__left">{i?.gateAccessory?.category?.name}</p>
                                        {i.position ? <p className="basket-item__right">
                                                {"Pozycja: " + i.position + " , Wyrówananie: "}
                                                {i.alignment === 1 &&
                                                    <span>do prawej</span> || i.alignment === 2 &&
                                                    <span>do lewej</span> || i.alignment === 3 &&
                                                    <span>do środka</span> || i.alignment === 4 &&
                                                    <span>do góry</span>}
                                            </p> :
                                            <p className="basket-item__right">{i?.gateAccessory?.name} {i?.gateAccessory?.name.toLocaleLowerCase() !== 'brak' ? `(x${i.quantity})` : ''}</p>}
                                    </div>
                                )
                            })}
                        </div>
                    </>}
                </div>

                {!isLoading && clickedItem === item.id && <div className="basket-right">
                    <div className="buttons-box"
                         style={params.type === "offers" || location.pathname === '/orders_clients' ? {maxWidth: "unset"} : null}>
                        {location.pathname === '/orders_clients' && <button
                            onClick={() => getPDF('get_pdf')}
                            className="main-button">Pobierz PDF<img
                            src={downloadIcon} alt="background image"/></button>}
                        {location.pathname === '/orders_clients' && <button
                            onClick={() => getPDF('client_get_pdf')}
                            className="main-button">Pobierz PDF dla klienta<img
                            src={downloadIcon} alt="background image"/></button>}
                        <button
                            onClick={() => {
                                dispatch(summaryBoxActions.updateCurrentStep(1))
                                createGateMutation.mutate({
                                        url: "/api/gates",
                                        data: {
                                            gateType: gateDetailed?.gateType?.id,
                                            client: user.userId
                                        }
                                    }, {
                                        onSuccess: (data) => {
                                            updateGateMutation.mutate({
                                                url: "/api/gates",
                                                id: data.id,
                                                data: {
                                                    ...item.gate,
                                                    id: data.id,
                                                    gateType: gateDetailed?.gateType?.id,
                                                    width: gateDetailed?.width?.id,
                                                    height: gateDetailed?.height?.id,
                                                    depth: gateDetailed?.depth,
                                                    rightArea: gateDetailed?.rightArea,
                                                    leftArea: gateDetailed?.leftArea,
                                                    lintel: gateDetailed?.lintel?.id,
                                                    drive: gateDetailed?.drive?.id,
                                                    leading: gateDetailed?.leading?.id,
                                                    componentItems: gateDetailed?.componentItems.map(e => e.id),
                                                }
                                            })
                                            gateDetailed.gateAccessories.forEach(e => {
                                                addGateAccessoriesToCartMutation.mutate({
                                                    url: `/api/gates/${data.id}/add_gate_accessory`,
                                                    data: {
                                                        gate_accessory: e.gateAccessory.id
                                                    }
                                                })
                                            })

                                            dispatch(creatorActions.updateCreator({
                                                id: data.id,
                                                isOffer: params.type === "offers",
                                                isOrder: params.type === "offers"
                                            }))
                                            history.push('/creator/step/1')
                                            if (item?.drives?.length > 0) {
                                                dispatch(summaryBoxActions.updateNumberOfSteps(5))
                                            } else {
                                                dispatch(summaryBoxActions.updateNumberOfSteps(4))
                                            }
                                        }
                                    }
                                )

                                dispatch(creatorActions.updateCreator({
                                    chosenGate: gateDetailed.gateType,
                                    ...gateDetailed
                                }))
                            }
                            }
                            className="main-button">Kopiuj<img
                            src={copyIcon} alt="background image"/>
                        </button>

                        {createGateMutation.isSuccess &&
                            <p style={{textAlign: "center", color: "green"}}>Zamówienie z oferty zostało utworzone!</p>}
                        {createGateMutation.isError &&
                            <p style={{textAlign: "center", color: "red"}}>Z tej oferty zostało juz utworzone
                                zamównienie!</p>}
                    </div>
                </div>}
            </div>

        )
            ;
    }
;

export default CustomGateDetails;
