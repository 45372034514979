import React, {useEffect, useState} from 'react';
import {uiActions} from "../../../store/slice/uiSlice";
import {creatorActions} from "../../../store/slice/gateCreatorSlice";
import eyeIcon from "../../../assets/icons/eye.png";
import {motion} from "framer-motion";
import {useDispatch} from "react-redux";
import {useMutation, useQueryClient} from "react-query";
import {
    authorizedArrayAccPatchWithId,
    authorizedDeleteWithId,
    authorizedGet,
    authorizedPatchWithId
} from "../CreatorFunctions";
import {visualizationActions} from "../../../store/slice/visualizationSlice";
import {NumberInput} from "@mantine/core";

const ExtraAccessoryItem = ({
                                accessory,
                                chosenAccessories,
                                creatorItem,
                                setChosenAccessories,
                                accessoryType,
                                noAccItems
                            }) => {
    const dispatch = useDispatch();
    const [alignment, setAlignment] = useState(null)
    const [qty, setQty] = useState(1);
    const [openEdit, setOpenEdit] = useState(false)
    const accTypeId = accessoryType?.id;

    const addGateAccessoriesToCartMutation = useMutation(authorizedPatchWithId, {
        onSuccess: (s) => {
            dispatch(creatorActions.updateItemDetailExtraAccessories(s.gateAccessories))
            dispatch(creatorActions.updateCreator(s))
            setOpenEdit(false)
        }
    })
    const addGateAccessoriesToCartMutation2 = useMutation(authorizedArrayAccPatchWithId, {
        onSuccess: (s) => {
            const lastItem = s[s.length - 1]
            dispatch(creatorActions.updateItemDetailExtraAccessories(lastItem?.gateAccessories))
            dispatch(creatorActions.updateCreator(lastItem))
            setOpenEdit(false)
        }
    })

    const addGateAccessoriesPositionMutation = useMutation(authorizedPatchWithId, {
        onSuccess: (s) => {
            let updatedGateAccessories = {...s}
            authorizedGet(s.gateAccessory).then(r => {
                updatedGateAccessories = {
                    ...s,
                    gateAccessory: r,
                }
                dispatch(creatorActions.updateItemDetailExtraAccessoriesPosition(updatedGateAccessories))
            })
            setChosenAccessories(null)
            setOpenEdit(false)
        }
    })
    useEffect(() => {
        if (chosenAccessories?.id !== accessory.id) {
            setOpenEdit(false)
        }
    }, [chosenAccessories])
    const qtyFromAccessory = creatorItem.gateAccessories.filter(acc => acc?.id === accessory.id)
    const queryClient = useQueryClient();
    const deleteCartItemMutation = useMutation(authorizedDeleteWithId, {
        onMutate: async ({id}) => {
            await queryClient.cancelQueries(['gateAcc3'])
            const prev = queryClient.getQueryData(['gateAcc3', creatorItem.gateAccessories.length])


            return {prev}


        },
        onError: (err, vars, ctx) => {

        },
        onSuccess: () => {
            queryClient.refetchQueries(['gateAcc'])
        },
    });
    return (
        <motion.div
            initial={{opacity: 0, y: 5}}
            animate={{opacity: 1, y: 0}}
            transition={{
                default: {
                    duration: .1
                }
            }
            }
            style={{
                backgroundImage: ` linear-gradient(black, white),url(${process.env.REACT_APP_API_UPLOADS + accessory?.image?.contentUrl})`,
                backgroundSize: "cover",
                backgroundBlendMode: "screen",
                maxWidth: '250px'
            }}
            key={accessory.id}
            className="content-item content-item3">
            {
                <a className="content-item__icon"
                   style={{cursor: "pointer"}}
                   onClick={() => {
                       dispatch(uiActions.chosenAccessory(accessory))
                       dispatch(creatorActions.showDescription())
                   }}>
                    <img
                        src={eyeIcon}
                        alt="icon"/>
                </a>}
            {
                <p className="content-item__title">{accessory.name}</p>}
            {openEdit && (chosenAccessories?.id === accessory.id) && <>
                {accessory?.maxQuantity > 1 && <div className="form--inp">
                    <label>Ilość</label>
                    <NumberInput
                        defaultValue={qtyFromAccessory.length > 0 ? qtyFromAccessory[0].qty : 1}
                        min={1}
                        max={Math.floor((creatorItem?.width?.value * accessory?.perMeter) / 1000)}
                        size="xs"
                        onChange={(e) => {
                            setQty(e)
                        }
                        }
                    />
                </div>}
                {chosenAccessories?.id === accessory.id && <button
                    disabled={addGateAccessoriesToCartMutation.isLoading}
                    onClick={() => {
                        const chosenGateAccessory = creatorItem?.gateAccessories?.find(e => e.gateAccessory.id === accessory.id)
                        // addGateAccessoriesPositionMutation.mutate({
                        //     url: `/api/gate_accessory_items/${chosenGateAccessory.id}`,
                        //     data: {
                        //         position: +setQty,
                        //         alignment: +alignment
                        //     }
                        // })
                        // addGateAccessoriesToCartMutation.mutate({
                        //     url: `/api/gates/${creatorItem.id}/add_gate_accessory`,
                        //     data: {
                        //         gate_accessory: accessory.id,
                        //         quantity: qty
                        //     }
                        // })

                    }
                    }
                    className="content-item__link content-item__link--cart">Zapisz</button>}
            </>}

            {(qty === 1 && accessory.isCustomPosition && chosenAccessories?.id === accessory.id) && <motion.div
                initial={{opacity: 0, y: 5}}
                animate={{opacity: 1, y: 0}}
                transition={{
                    default: {
                        duration: .1
                    }
                }
                }
                className="form--inp">
                <label htmlFor="alignment">Pozycja elemenetu
                    <select
                        name="alignment"
                        id="alignment"
                        onChange={(e) => dispatch(visualizationActions.editGateAccessoriesRenderPosition(
                            {
                                accType: accTypeId,
                                accHorizonatalPos: +e.target.value,
                            }))}
                        style={{width: "100%"}}>
                        <option value="10">Brak...</option>
                        <option value="2">Do prawej</option>
                        <option value="0">Do lewej</option>
                        <option value="1">Do środka</option>
                    </select>
                </label>
            </motion.div>}
            {!openEdit && <button
                disabled={addGateAccessoriesToCartMutation.isLoading}
                onClick={() => {
                    const chosenGateAccessory = creatorItem?.gateAccessories?.find(e => e.gateAccessory?.id === accessory?.id)
                    const chosenGateAccessoryType = accessoryType?.gateAccessories?.find(e => e?.id === accessory?.id)

                    if (chosenGateAccessory) {
                        return;
                    } else {
                        if (accessory?.render?.contentUrl) {
                            // if (!accessory.isSoloRender) {
                            //     if (creatorItem.gateAccessories.some(e => e.gateAccessory.isSoloRender)) {
                            //         const itemToDelete = creatorItem.gateAccessories.find(e => e.gateAccessory.isSoloRender)
                            //         deleteCartItemMutation.mutate({
                            //             url: `/api/gate_accessory_items`,
                            //             id: itemToDelete.id,
                            //         }, {
                            //             onSuccess: () => {
                            //                 dispatch(visualizationActions.resetGateAccessoriesVisualization())
                            //                 dispatch(visualizationActions.addGateAccessoriesRender(
                            //                     {
                            //                         acc: accessory.id,
                            //                         accName: accessory.name,
                            //                         accType: accTypeId,
                            //                         accRender: accessory.render.contentUrl,
                            //                         accPanelPos: accessory.panelPosition,
                            //                         accHorizonatalPos: accessory.horizontalElementPosition,
                            //                         multipleByGateWidth: accessory?.multipleByGateWidth,
                            //                         renderQuantity: accessory?.renderQuantity
                            //                     }))
                            //             }
                            //         });
                            //     } else {
                            //         dispatch(visualizationActions.addGateAccessoriesRender(
                            //             {
                            //                 acc: accessory.id,
                            //                 accName: accessory.name,
                            //                 accType: accTypeId,
                            //                 accRender: accessory.render.contentUrl,
                            //                 accPanelPos: accessory.panelPosition,
                            //                 accHorizonatalPos: accessory.horizontalElementPosition,
                            //                 multipleByGateWidth: accessory?.multipleByGateWidth,
                            //                 renderQuantity: accessory?.renderQuantity
                            //             }))
                            //     }

                            // }
                            // if (accessory.isSoloRender && creatorItem.gateAccessories.length) {
                            //     const itemsToDelete = creatorItem.gateAccessories.filter(e => e.gateAccessory.isSoloRender === false)
                            //     if (itemsToDelete.length) {
                            //         for (let item of itemsToDelete) {
                            //             deleteCartItemMutation.mutate({
                            //                 url: `/api/gate_accessory_items`,
                            //                 id: item.id,
                            //             });
                            //         }
                            //         dispatch(visualizationActions.resetGateAccessoriesVisualization())
                            //         dispatch(visualizationActions.addGateAccessoriesRender(
                            //             {
                            //                 acc: accessory.id,
                            //                 accName: accessory.name,
                            //                 accType: accTypeId,
                            //                 accRender: accessory.render.contentUrl,
                            //                 accPanelPos: accessory.panelPosition,
                            //                 accHorizonatalPos: accessory.horizontalElementPosition,
                            //                 multipleByGateWidth: accessory?.multipleByGateWidth,
                            //                 renderQuantity: accessory?.renderQuantity
                            //             }))
                            //     } else {
                            //         dispatch(visualizationActions.resetGateAccessoriesVisualization())
                            //         dispatch(visualizationActions.addGateAccessoriesRender(
                            //             {
                            //                 acc: accessory.id,
                            //                 accName: accessory.name,
                            //                 accType: accTypeId,
                            //                 accRender: accessory.render.contentUrl,
                            //                 accPanelPos: accessory.panelPosition,
                            //                 accHorizonatalPos: accessory.horizontalElementPosition,
                            //                 multipleByGateWidth: accessory?.multipleByGateWidth,
                            //                 renderQuantity: accessory?.renderQuantity
                            //             }))
                            //     }
                            // } else {
                            //     dispatch(visualizationActions.addGateAccessoriesRender(
                            //         {
                            //             acc: accessory.id,
                            //             accName: accessory.name,
                            //             accType: accTypeId,
                            //             accRender: accessory.render.contentUrl,
                            //             accPanelPos: accessory.panelPosition,
                            //             accHorizonatalPos: accessory.horizontalElementPosition,
                            //             multipleByGateWidth: accessory?.multipleByGateWidth,
                            //             renderQuantity: accessory?.renderQuantity
                            //         }))
                            // }
                        }
                        setChosenAccessories(accessory)

                        if (accessory?.maxQuantity <= 1) {
                            addGateAccessoriesToCartMutation.mutate({
                                url: `/api/gates/${creatorItem.id}/add_gate_accessory`,
                                data: {
                                    gate_accessory: accessory.id,
                                    quantity: 1
                                }
                            }, {
                                onSuccess: (d) => {
                                    dispatch(visualizationActions.addGateAccessoriesRender(
                                        {
                                            acc: accessory.id,
                                            accName: accessory.name,
                                            accType: accTypeId,
                                            accRender: accessory.render.contentUrl,
                                            accPanelPos: accessory.panelPosition,
                                            accHorizonatalPos: accessory.horizontalElementPosition,
                                            multipleByGateWidth: accessory?.multipleByGateWidth,
                                            renderQuantity: accessory?.renderQuantity
                                        }))
                                    const findTheSamePanel = creatorItem.gateAccessories.filter(item => {
                                        return item.gateAccessory.panelPosition.some(el => accessory.panelPosition.some(e => e.name === el.name))
                                    })

                                    let noItems = noAccItems.filter(el => findTheSamePanel.find(e => e.gateAccessory.category.id === el.type.id))
                                    let noItemsFiltered = noItems.filter(el => el.type.id !== accTypeId)

                                    if (!!findTheSamePanel?.length && !!noItemsFiltered?.length) {
                                        for (const i of findTheSamePanel) {
                                            dispatch(visualizationActions.deleteImgVisualization(i.gateAccessory.id))
                                        }
                                        addGateAccessoriesToCartMutation2.mutate({
                                            url: `/api/gates/${creatorItem.id}/add_gate_accessory`,
                                            items: noItemsFiltered
                                        })
                                    }
                                }
                            })
                        } else {
                            setOpenEdit(true)
                        }
                    }
                }
                }
                className={`content-item__link ${creatorItem?.gateAccessories?.some(e => e?.gateAccessory?.id === accessory?.id) ? "chosen" : null}`}/>}

        </motion.div>
    );
};

export default ExtraAccessoryItem;
