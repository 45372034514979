import React from 'react';
import {motion} from "framer-motion";
import {useMutation, useQuery} from "react-query";
import {
    authorizedDeleteWithId,
    authorizedGet,
    authorizedPatch,
    authorizedPatchWithId
} from "../layouts/Creator/CreatorFunctions";
import {creatorActions} from "../store/slice/gateCreatorSlice";
import {useDispatch, useSelector} from "react-redux";
import {NumberInput} from "@mantine/core";

const SummaryBoxAccessories = ({accessory, creatorItem}) => {
    const dispatch = useDispatch();

    const changeAccessoryQuantity = useMutation(authorizedPatchWithId, {
        onSuccess: (s) => {
            dispatch(creatorActions.updateQuantityAccessories(s))
        }
    });
    const {
        data: clientCart,
    } = useQuery(["cart"], () => authorizedGet(`/api/offer_carts`), {
        placeholderData: []
    })

    return (
        <>
            <motion.div
                initial={{opacity: 0, y: 5}}
                animate={{opacity: 1, y: 0}}
                transition={{
                    default: {
                        duration: .1
                    }
                }
                }
                key={accessory.id} className="summary-box__item">
                <div className="summary-box__accessories"><p>{accessory?.name}</p></div>
                <div className="summary-box__price">
                    <div className="content-accordion-input-box">
                        <div style={{display: "flex", alignItems: "center", gap: 5}}>
                            <p>Ilość: </p>
                            <NumberInput
                                defaultValue={1}
                                min={1}
                                size="xs"
                                style={{maxWidth: 50}}
                                onChange={(e) => {
                                    dispatch(creatorActions.updateItemDetailQtyAccessories({
                                        accessory: accessory.id,
                                        qty: e
                                    }))
                                }
                                }
                            />
                        </div>

                    </div>
                    <button
                        onClick={() => {
                            const acc = clientCart[0]?.accessories.find(a => a.accessory.id === accessory.id)
                            if (acc) {
                                authorizedDeleteWithId({url: `/api/${creatorItem.isOffer ? "offer_cart_accessories" : "cart_accessories"}`, id: acc.id})
                            }
                            dispatch(creatorActions.deleteItemAccessories(accessory.id))
                        }}
                    >&#10006;
                    </button>
                </div>

            </motion.div>
            <div className="summary-box__item">
                <p>KTM</p>
                <p>{accessory?.ktm}</p>
            </div>
            <div className="summary-box__item">
                <p>Opis skrócony</p>
                <p>{accessory?.ktmDescription}</p>
            </div>
        </>
    );
};

export default SummaryBoxAccessories;
