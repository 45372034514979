import React, {useState} from 'react';
import {useMutation} from "react-query";
import {useHistory, useParams} from "react-router-dom";
import {authorizedPost} from "../Creator/CreatorFunctions";
import {useDispatch} from "react-redux";
import arrowUpIcon from "../../assets/icons/expanded.png";
import {summaryBoxActions} from "../../store/slice/summarySlice";
import copyIcon from "../../assets/icons/copy.png";
import {creatorActions} from "../../store/slice/gateCreatorSlice";

const CustomAccessories = ({item}) => {
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory()
    const [clickedItem, setClickedItem] = useState(null);
    const accessToken = localStorage.getItem("token");
    const createGateMutation = useMutation(authorizedPost, {
        onSuccess: (e) => {
            setTimeout(() => {
                createGateMutation.reset()
            }, 2000)
        },
        onError: (e) => {
            setTimeout(() => {
                createGateMutation.reset()
            }, 2000)
        }
    })

    return (
        <div style={{display: "flex", flexWrap: 'wrap'}}>
            {<div className="basket-left">

                {(item?.orderData?.accessories?.length > 0 || item?.offerData?.accessories?.length > 0) &&
                    <div className="basket-box">
                        {!!item?.offerData?.accessories.length && item?.offerData?.accessories?.map((acc, index) => {
                            return (<div style={{marginTop: 20}}>
                                    <div className="basket-box">
                                        <div className="basket-item">
                                            <p className="basket-item__left basket-item__left--round">{item?.offerData?.items?.length + index + 1}</p>

                                        </div>
                                    </div>
                                    <div className="basket-box">
                                        <div className="basket-item">
                                            <p className="basket-item__left">KTM</p>
                                            <p className="basket-item__right">{acc?.ktm}</p>
                                        </div>
                                    </div>
                                    <div className="basket-box">
                                        <div className="basket-item">
                                            <p className="basket-item__left">KTM - opis</p>
                                            <p className="basket-item__right">{acc?.ktmDescription || "-"}</p>
                                        </div>
                                    </div>
                                    <div className="basket-box">
                                        <div className="basket-item">
                                            <p className="basket-item__left">Ilość</p>
                                            <p className="basket-item__right">{acc?.quantity || "-"}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        {!!item?.orderData?.accessories.length && item?.orderData?.accessories?.map((acc, index) => {
                            return (<><div style={{marginTop: 20}}>
                                    <div className="basket-box">
                                        <div className="basket-item">
                                            <p className="basket-item__left basket-item__left--round">{item?.orderData?.items?.length + index + 1}</p>
                                            <p className="basket-item__right">
                                                <div
                                                    className="accordion-icons flex">
                                                    <img
                                                        className={`accordion-icons__expand ${clickedItem !== item.id ? "show" : ""}`}
                                                        onClick={(e) => {
                                                            setClickedItem(prev => {
                                                                if (prev === item.id) {
                                                                    return null
                                                                } else {
                                                                    return item.id
                                                                }
                                                            })
                                                        }} src={arrowUpIcon}
                                                        alt="accordion icon"/>
                                                </div>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="basket-box">
                                        <div className="basket-item">
                                            <p className="basket-item__left">KTM</p>
                                            <p className="basket-item__right">{acc?.ktm}</p>
                                        </div>
                                    </div>
                                    <div className="basket-box">
                                        <div className="basket-item">
                                            <p className="basket-item__left">KTM - opis</p>
                                            <p className="basket-item__right">{acc?.ktmDescription || "-"}</p>
                                        </div>
                                    </div>
                                    <div className="basket-box">
                                        <div className="basket-item">
                                            <p className="basket-item__left">Ilość</p>
                                            <p className="basket-item__right">{acc?.quantity || "-"}</p>
                                        </div>
                                    </div>
                                </div>
                                    {clickedItem === item.id && <div className="basket-right">
                                        <div className="buttons-box" style={params.type === "offers" ? {maxWidth: "unset"} : null}>
                                            <button
                                                onClick={() => {

                                                    dispatch(summaryBoxActions.updateCurrentStep(4))
                                                    dispatch(creatorActions.updateCreator({
                                                        isOffer: params.type === "offers",
                                                        isOrder: params.type === "offers"
                                                    }))
                                                    dispatch(creatorActions.updateItemDetailAccessories({...acc, id : acc.accessory}))
                                                    history.push('/creator/step/4')
                                                    dispatch(summaryBoxActions.updateNumberOfSteps(4))

                                                }
                                                }
                                                className="main-button">Kopiuj<img
                                                src={copyIcon} alt="background image"/>
                                            </button>
                                            {createGateMutation.isSuccess &&
                                                <p style={{textAlign: "center", color: "green"}}>Zamówienie z oferty zostało utworzone!</p>}
                                            {createGateMutation.isError &&
                                                <p style={{textAlign: "center", color: "red"}}>Z tej oferty zostało juz utworzone
                                                    zamównienie!</p>}
                                        </div>
                                    </div>}
                                </>
                            )
                        })}
                    </div>}
            </div>}
        </div>);
};

export default CustomAccessories;
